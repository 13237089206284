import React from 'react';
import './BasketballLogo.scss';
import { Form, Image, Input } from "antd";
import { FormInstance } from "antd/es/form";

interface BasketballLogoProps {
    formRef: React.RefObject<FormInstance>;
    competition: string;
    basketballLogoUrl: string;
    previewBasketballLogo: boolean;
    showBasketballLogo: boolean;
    handCompetitionChange: (competition: string) => void;
    handBasketballLogoUrlChange: (basketballLogoUrl: string) => void;
    show: () => void;
    preview: () => void;
    set: () => void;
}

export class BasketballLogo extends React.Component<BasketballLogoProps, unknown>{

    public render(): JSX.Element {
        return (
            <Form
                ref={this.props.formRef}
                className='basketballLogoUrlDiv'
            >
                <div className='basketballLogoUrl'>
                    <p>直播图标</p>
                </div>
                <div className='basketballLogoUrlController'>
                    <div>
                        <div style={{marginTop: '5px', width: '85%'}}>
                            <p style={{display: 'flex', float: 'left', margin: 'auto 20px'}}>比赛名称：</p>
                            <Form.Item
                                name='competition'
                                rules={[
                                    // { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                    { max: 14, message: '名称不超过14个字' }]}
                            >
                                <Input
                                    style={{background: '#17242D', color: '#FFFFFF'}}
                                    value={this.props.competition}
                                    placeholder={'不超过14个字'}
                                    bordered={false}
                                    maxLength={14}
                                    onChange={(e) => this.props.handCompetitionChange(e.target.value)}
                                />
                            </Form.Item>
                        </div>
                        <div style={{width: '85%'}}>
                            <p style={{display: 'flex', float: 'left', margin: 'auto 20px'}}>图片地址：</p>
                            <Form.Item
                                name='logoUrl'
                                validateStatus="warning"
                                help={'建议格式：*.png;*.jpeg;*.jpg;*.bmp'}
                            >
                                <Input
                                    style={{background: '#17242D', color: '#FFFFFF'}}
                                    value={this.props.basketballLogoUrl}
                                    placeholder={'请输入图片链接地址'}
                                    bordered={false}
                                    onChange={(e) => this.props.handBasketballLogoUrlChange(e.target.value)}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <p style={{display: 'flex', float: 'left', margin: 'auto 20px'}}>图片预览：</p>
                            <Image
                                style={{marginTop: '5px', marginLeft: '20px'}}
                                width={80}
                                src={this.props.basketballLogoUrl}
                                preview={false}
                            />
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: '3px'}}>
                    <button onClick={() => this.props.preview()}
                            style={{background: `${this.props.previewBasketballLogo ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                        {this.props.previewBasketballLogo ? '隐藏' : '预览'}
                    </button>
                    <button onClick={() => this.props.show()}
                            style={{background: `${this.props.showBasketballLogo ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '20px'}}>
                        {this.props.showBasketballLogo ? '隐藏' : '输出'}
                    </button>
                    <button onClick={() => this.props.set()}
                            style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                        刷新
                    </button>
                </div>
            </Form>
        );
    }
}
