import { Socket } from 'socket.io-client';
import {TeamMembers} from "../common/types";

class BasketballService {
    public listen(socket: Socket): void {
        socket.emit("controller", {
            type: "listenBasketball",
        });
    }

    public previewCommentary(socket: Socket, commentator: string, guest: string,  venue: string, previewCommentary: boolean): void {
        socket.emit('controller', {
            type: 'previewCommentary',
            showCommentary: previewCommentary,
            commentator,
            guest,
            venue,
        })
    }

    public showCommentary(socket: Socket, commentator: string, guest: string,  venue: string, showCommentary: boolean): void {
        socket.emit('controller', {
            type: 'showCommentary',
            commentator,
            guest,
            venue,
            showCommentary,
        })
    }

    public setCommentary(socket: Socket, commentator: string, guest: string,  venue: string): void {
        socket.emit('controller', {
            type: 'setCommentary',
            commentator,
            guest,
            venue,
        })
    }

    public previewBasketballScoreCard(socket: Socket, team1Logo: string, team2Logo: string, name1: string, name2: string, score1: number, score2: number, word: string, previewBasketballScoreCard: boolean): void {
        socket.emit('controller', {
            type: 'previewBasketballScoreCard',
            showBasketballScoreCard: previewBasketballScoreCard,
            team1Logo,
            team2Logo,
            name1,
            name2,
            score1,
            score2,
            word
        })
    }

    public showBasketballScoreCard(socket: Socket, team1Logo: string, team2Logo: string, name1: string, name2: string, score1: number,score2: number, word: string, showBasketballScoreCard: boolean): void {
        socket.emit('controller', {
            type: 'showBasketballScoreCard',
            showBasketballScoreCard,
            team1Logo,
            team2Logo,
            name1,
            name2,
            score1,
            score2,
            word
        })
    }

    public setBasketballScoreCard(socket: Socket, team1Logo: string, team2Logo: string, name1: string, name2: string, score1: number,score2: number, word: string): void {
        socket.emit('controller', {
            type: 'setBasketballScoreCard',
            team1Logo,
            team2Logo,
            name1,
            name2,
            score1,
            score2,
            word
        })
    }

    public previewBasketballLogo(socket: Socket, basketballLogoUrl: string, competition: string, previewBasketballLogo: boolean): void {
        socket.emit('controller', {
            type: 'previewBasketballLogo',
            showBasketballLogo: previewBasketballLogo,
            basketballLogoUrl,
            competition
        })
    }

    public showBasketballLogo(socket: Socket, basketballLogoUrl: string, competition: string, showBasketballLogo: boolean): void {
        socket.emit('controller', {
            type: 'showBasketballLogo',
            showBasketballLogo,
            basketballLogoUrl,
            competition
        })
    }

    public setBasketballLogo(socket: Socket, basketballLogoUrl: string, competition: string): void {
        socket.emit('controller', {
            type: 'setBasketballLogo',
            basketballLogoUrl,
            competition
        })
    }

    public previewBasketballCaption(socket: Socket, title: string, text: string, previewBasketballCaption: boolean): void {
        socket.emit('controller', {
            type: 'previewBasketballCaption',
            showBasketballCaption: previewBasketballCaption,
            title,
            text
        })
    }

    public showBasketballCaption(socket: Socket, title: string, text: string, showBasketballCaption: boolean): void {
        socket.emit('controller', {
            type: 'showBasketballCaption',
            showBasketballCaption,
            title,
            text
        })
    }

    public setBasketballCaption(socket: Socket, title: string, text: string): void {
        socket.emit('controller', {
            type: 'setBasketballCaption',
            title,
            text
        })
    }

    public previewBasketballCompetitor(socket: Socket, name1: string, name2: string, team1Logo: string, team2Logo: string, team1Members: TeamMembers, team2Members: TeamMembers, previewBasketballCompetitor: boolean, currentTeam: number): void {
        socket.emit('controller', {
            type: 'previewBasketballCompetitor',
            showBasketballCompetitor: previewBasketballCompetitor,
            team1Members,
            team2Members,
            name1,
            name2,
            team1Logo,
            team2Logo,
            currentTeam
        })
    }

    public showBasketballCompetitor(socket: Socket, name1: string, name2: string, team1Logo: string, team2Logo: string, team1Members: TeamMembers, team2Members: TeamMembers, showBasketballCompetitor: boolean, currentTeam: number): void {
        socket.emit('controller', {
            type: 'showBasketballCompetitor',
            showBasketballCompetitor,
            team1Members,
            team2Members,
            name1,
            name2,
            team1Logo,
            team2Logo,
            currentTeam
        })
    }

    public setBasketballCompetitor(socket: Socket, name1: string, name2: string, team1Logo: string, team2Logo: string, team1Members: TeamMembers, team2Members: TeamMembers, currentTeam: number): void {
        socket.emit('controller', {
            type: 'setBasketballCompetitor',
            team1Members,
            team2Members,
            name1,
            name2,
            team1Logo,
            team2Logo,
            currentTeam
        })
    }

    public previewBasketballTotalScore(socket: Socket, competition: string, word: string, name1: string, name2: string, score1: number, score2: number, team1Logo: string, team2Logo: string, previewBasketballTotalScore: boolean): void {
        socket.emit('controller', {
            type: 'previewBasketballTotalScore',
            showBasketballTotalScore: previewBasketballTotalScore,
            competition,
            word,
            name1,
            name2,
            score1,
            score2,
            team1Logo,
            team2Logo,
        })
    }

    public showBasketballTotalScore(socket: Socket, competition: string, word: string, name1: string, name2: string, score1: number, score2: number, team1Logo: string, team2Logo: string, showBasketballTotalScore: boolean): void {
        socket.emit('controller', {
            type: 'showBasketballTotalScore',
            showBasketballTotalScore,
            competition,
            word,
            name1,
            name2,
            score1,
            score2,
            team1Logo,
            team2Logo,
        })
    }

    public setBasketballTotalScore(socket: Socket, competition: string, word: string, name1: string, name2: string, score1: number, score2: number, team1Logo: string, team2Logo: string,): void {
        socket.emit('controller', {
            type: 'setBasketballTotalScore',
            competition,
            word,
            name1,
            name2,
            score1,
            score2,
            team1Logo,
            team2Logo,
        })
    }
}

export const basketballService = new BasketballService();
