import React from 'react';
import './BasketballCompetitor.scss';
import { Form, Image, Input, InputNumber, Select } from "antd";
import { FormInstance } from "antd/es/form";
import { TeamMembers } from "../../../../common/types";

interface BasketballCompetitorProps {
    formRef: React.RefObject<FormInstance>;
    name1: string;
    name2: string;
    team1Logo: string;
    team2Logo: string;
    currentTeam: number;
    team1Members: TeamMembers;
    team2Members: TeamMembers;
    previewBasketballCompetitor: boolean;
    showBasketballCompetitor: boolean;
    changeTeam: (value: number) => void;
    handTeamLogo1Change: (logo: string) => void;
    handTeamLogo2Change: (logo: string) => void;
    handCurrentTeamMembersChange: (currentTeamMembers: TeamMembers) => void;
    show: () => void;
    preview: () => void;
    set: () => void;
}

const { Option } = Select;

export class BasketballCompetitor extends React.Component<BasketballCompetitorProps, any>{

    public render(): JSX.Element {
        return (
            <Form
                ref={this.props.formRef}
                className='basketballCompetitor'>
                <div className='basketballCompetitorDiv'>
                    <p>首发名单</p>
                </div>
                <div>
                    <div style={{display: 'flex', marginTop: '5px', width: '85%'}}>
                        <p style={{width: '80px', display: 'flex', float: 'left', margin: 'auto 21px auto 20px'}}>选择球队：</p>
                        <Select
                            style={{ borderRadius: '2px', width: '80%', height: '29px', background: '#17242D', color: '#FFFFFF', marginTop: '5px'}}
                            bordered={false}
                            value={this.props.currentTeam}
                            placeholder={'请选择球队'}
                            onChange={(value: number) => this.props.changeTeam(value)}
                        >
                            <Option value={0}>{this.props.name1}</Option>
                            <Option value={1}>{this.props.name2}</Option>
                        </Select>
                    </div>
                    <div style={{marginTop: '5px', width: '85%'}}>
                        <p style={{display: 'flex', float: 'left', margin: 'auto 20px'}}>球队logo：</p>
                        <Form.Item
                            name='logoUrl'
                            validateStatus="warning"
                            help={'建议格式：*.png;*.jpeg;*.jpg;*.bmp'}
                        >
                            <Input
                                style={{background: '#17242D', color: '#FFFFFF'}}
                                value={this.props.currentTeam === 0 ? this.props.team1Logo : this.props.team2Logo}
                                placeholder={'请输入图片链接地址'}
                                bordered={false}
                                onChange={(e) => {
                                    this.props.currentTeam === 0 ?
                                        this.props.handTeamLogo1Change(e.target.value) :
                                        this.props.handTeamLogo2Change(e.target.value)
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div style={{height: '50px'}}>
                        <p style={{display: 'flex', float: 'left', margin: 'auto 20px'}}>logo预览：</p>
                        <Image
                            style={{marginTop: '20px', marginLeft: '20px'}}
                            width={60}
                            src={this.props.currentTeam === 0 ? this.props.team1Logo : this.props.team2Logo}
                            preview={false}
                        />
                    </div>
                    <div style={{display: 'flex', float: 'left', justifyContent: 'space-around', width: '100%', height: '20px'}}>
                        <p style={{width: '70px', textAlign: 'center'}}>编号</p>
                        <p style={{width: '105px', textAlign: 'center'}}>姓名</p>
                        <p style={{width: '190px', textAlign: 'center'}}>拼音</p>
                    </div>
                    <div style={{display: 'flex', float: 'left', justifyContent: 'space-around', width: '100%', marginTop: '5px', height: '22px'}}>
                        <InputNumber
                            placeholder={'0~999'}
                            size={"small"}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member1?.number : this.props.team2Members.member1?.number}
                            min={0}
                            max={999}
                            precision={0}
                            style={{width: '70px', textAlign: 'center'}}
                            bordered={false}
                            onChange={(value: number) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member1 = {...currentTeamMembers.member1, number: value === null ? undefined : value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                        <Input
                            placeholder={'不超过6个字'}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member1?.name : this.props.team2Members.member1?.name}
                            style={{width: '105px', height: '22px', textAlign: 'center'}}
                            bordered={false}
                            maxLength={6}
                            onChange={(e) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member1 = {...currentTeamMembers.member1, name: e.target.value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                        <Input
                            placeholder={'不超过36个字'}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member1?.phonetic : this.props.team2Members.member1?.phonetic}
                            style={{width: '190px', height: '22px', textAlign: 'center'}}
                            bordered={false}
                            maxLength={36}
                            onChange={(e) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member1 = {...currentTeamMembers.member1, phonetic: e.target.value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                    </div>
                    <div style={{display: 'flex', float: 'left', justifyContent: 'space-around', width: '100%', marginTop: '5px', height: '22px'}}>
                        <InputNumber
                            placeholder={'0~999'}
                            size={"small"}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member2?.number : this.props.team2Members.member2?.number}
                            min={0}
                            max={999}
                            precision={0}
                            style={{width: '70px', textAlign: 'center'}}
                            bordered={false}
                            onChange={(value: number) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member2 = {...currentTeamMembers.member2, number: value === undefined ? 0 : value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                        <Input
                            placeholder={'不超过6个字'}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member2?.name : this.props.team2Members.member2?.name}
                            style={{width: '105px', height: '22px', textAlign: 'center'}}
                            bordered={false}
                            maxLength={6}
                            onChange={(e) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member2 = {...currentTeamMembers.member2, name: e.target.value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                        <Input
                            placeholder={'不超过36个字'}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member2?.phonetic : this.props.team2Members.member2?.phonetic}
                            style={{width: '190px', height: '22px', textAlign: 'center'}}
                            bordered={false}
                            maxLength={36}
                            onChange={(e) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member2 = {...currentTeamMembers.member2, phonetic: e.target.value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                    </div>
                    <div style={{display: 'flex', float: 'left', justifyContent: 'space-around', width: '100%', marginTop: '5px', height: '22px'}}>
                        <InputNumber
                            placeholder={'0~999'}
                            size={"small"}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member3?.number : this.props.team2Members.member3?.number}
                            min={0}
                            max={999}
                            precision={0}
                            style={{width: '70px', textAlign: 'center'}}
                            bordered={false}
                            onChange={(value: number) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member3 = {...currentTeamMembers.member3, number: value === undefined ? 0 : value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                        <Input
                            placeholder={'不超过6个字'}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member3?.name : this.props.team2Members.member3?.name}
                            style={{width: '105px', height: '22px', textAlign: 'center'}}
                            bordered={false}
                            maxLength={6}
                            onChange={(e) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member3 = {...currentTeamMembers.member3, name: e.target.value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                        <Input
                            placeholder={'不超过36个字'}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member3?.phonetic : this.props.team2Members.member3?.phonetic}
                            style={{width: '190px', height: '22px', textAlign: 'center'}}
                            bordered={false}
                            maxLength={36}
                            onChange={(e) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member3 = {...currentTeamMembers.member3, phonetic: e.target.value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                    </div>
                    <div style={{display: 'flex', float: 'left', justifyContent: 'space-around', width: '100%', marginTop: '5px', height: '22px'}}>
                        <InputNumber
                            placeholder={'0~999'}
                            size={"small"}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member4?.number : this.props.team2Members.member4?.number}
                            min={0}
                            max={999}
                            precision={0}
                            style={{width: '70px', textAlign: 'center'}}
                            bordered={false}
                            onChange={(value: number) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member4 = {...currentTeamMembers.member4, number: value === undefined ? 0 : value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                        <Input
                            placeholder={'不超过6个字'}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member4?.name : this.props.team2Members.member4?.name}
                            style={{width: '105px', height: '22px', textAlign: 'center'}}
                            bordered={false}
                            maxLength={6}
                            onChange={(e) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member4 = {...currentTeamMembers.member4, name: e.target.value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                        <Input
                            placeholder={'不超过36个字'}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member4?.phonetic : this.props.team2Members.member4?.phonetic}
                            style={{width: '190px', height: '22px', textAlign: 'center'}}
                            bordered={false}
                            maxLength={36}
                            onChange={(e) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member4 = {...currentTeamMembers.member4, phonetic: e.target.value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                    </div>
                    <div style={{display: 'flex', float: 'left', justifyContent: 'space-around', width: '100%', marginTop: '5px', height: '22px'}}>
                        <InputNumber
                            placeholder={'0~999'}
                            size={"small"}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member5?.number : this.props.team2Members.member5?.number}
                            min={0}
                            max={999}
                            precision={0}
                            style={{width: '70px', textAlign: 'center'}}
                            bordered={false}
                            onChange={(value: number) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member5 = {...currentTeamMembers.member5, number: value === undefined ? 0 : value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                        <Input
                            placeholder={'不超过6个字'}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member5?.name : this.props.team2Members.member5?.name}
                            style={{width: '105px', height: '22px', textAlign: 'center'}}
                            bordered={false}
                            maxLength={6}
                            onChange={(e) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member5 = {...currentTeamMembers.member5, name: e.target.value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                        <Input
                            placeholder={'不超过36个字'}
                            value={this.props.currentTeam === 0 ? this.props.team1Members.member5?.phonetic : this.props.team2Members.member5?.phonetic}
                            style={{width: '190px', height: '22px', textAlign: 'center'}}
                            bordered={false}
                            maxLength={36}
                            onChange={(e) => {
                                const currentTeamMembers = this.props.currentTeam === 0 ? this.props.team1Members : this.props.team2Members;
                                currentTeamMembers.member5 = {...currentTeamMembers.member5, phonetic: e.target.value};
                                this.props.handCurrentTeamMembersChange(currentTeamMembers);
                            }}
                        />
                    </div>
                </div>
                <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'flex-end', marginTop: '13px'}}>
                    <button onClick={() => this.props.preview()}
                            style={{background: `${this.props.previewBasketballCompetitor ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                        {this.props.previewBasketballCompetitor ? '隐藏' : '预览'}
                    </button>
                    <button onClick={() => this.props.show()}
                            style={{background: `${this.props.showBasketballCompetitor ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '20px'}}>
                        {this.props.showBasketballCompetitor ? '隐藏' : '输出'}
                    </button>
                    <button onClick={() => this.props.set()}
                            style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                        刷新
                    </button>
                </div>
            </Form>
        );
    }
}
