import { HOST_NAME } from './constants';
import dayjs from 'dayjs';
import dayjsutc from 'dayjs/plugin/utc';
import { Modal } from 'antd';
import * as http from 'http';
import * as https from 'https';
import {history} from "./history";
dayjs.extend(dayjsutc);

export function notNull<T>(value: T | null | undefined): T {
  if (value === null || value === undefined) {
    throw new Error(`value is null`);
  }
  return value;
}

export function notNullOrSpace(value: string): boolean {
  return !!(value && (/^\S.*\S$|(^\S{0,1}\S$)/.test(value)));
}

export function notSpace(value: string): boolean {
  return (value === '') || (/^\S.*\S$|(^\S{0,1}\S$)/.test(value));
}

export async function confirm(message: string): Promise<boolean> {
  return new Promise(resolve => {
    Modal.confirm({
      title: message,
      okText: '确认',
      cancelText: '取消',
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    });
  });
}

export function replaceUrlParams(url: string, params: object): string {
  Object.entries(params).forEach(([key, value]) => {
    url = url.replace(`:${key}`, value);
  });
  return url;
}

export function getHostname(): string {
  return HOST_NAME || new URL(window.location.href).hostname;
}

export function formatDate(isoDate: string | Date, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
  return dayjs(isoDate).format(format);
}

export function fileSize(url: string): Promise<number | undefined> {
  return new Promise((resolve, reject) => {
    const req = (url.startsWith('https') ? https : http).get(url);
    req.on("response", (response: any) => {
      console.log(response);
      req.abort();
      let length = parseInt(response.headers['content-length']);
      if (isNaN(length)) reject("Couldn't get file size");
      else {
        resolve(length);
      }
    });
    req.on("error", (error: Error) =>
        reject(error)
    );
  })
}

export function isDev(): boolean {
  return process.env.NODE_ENV === 'development';
}

export function goBack(): void {
  history.goBack();
}
