export const PUBLISH_BASE_URL = process.env.REACT_APP_PUBLISH_BASE_URL || '';

export const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL || '';

export const HOST_NAME = process.env.REACT_APP_HOST_NAME || '';

export const INFO_BASE_URL = process.env.REACT_APP_INFO_BASE_URL || '';

export const CAPTION_BASE_URL = process.env.REACT_APP_CAPTION_BASE_URL || '';

export const CAPTION_SHOW_URL = process.env.REACT_APP_CAPTION_SHOW_URL || '';

export const SWITCHER_BASE_URL = process.env.REACT_APP_SWITCHER_BASE_URL || '';

export const EUVP_BASE_URL = process.env.REACT_APP_EUVP_BASE_URL || '';
