import './Home.scss';
import React from 'react';
import { authService } from "../../services/AuthService";
import { message } from "antd";
import { Login } from "../login/Login";
import { euvpService } from "../../services/EuvpService";
import { history } from '../../common/history';

export class Home extends React.Component<unknown, unknown> {
    public render() {
        return (
            <div className='home'>
                <Login onLogin={(username: string, password: string, enableEuvp: boolean, captcha: string, captchaPicId?: string) =>
                    this.login(username, password, enableEuvp, captcha, captchaPicId)}/>
            </div>
        );
    }

    private async login(username: string, password: string, enableEuvp: boolean, captcha: string, captchaPicId?: string): Promise<void> {
        try {
            if (enableEuvp) {
                const result = await euvpService.login(username, password, captcha, captchaPicId || '');
                if (result && result.ret && result.ret.code !== 0) {
                    message.error(result.ret.cn).then();
                } else if (result && result.data && result.data.token) {
                    if (!result.data.routes.some(route => route.id === 47)) {
                        message.error('没有云导播台权限，请联系管理员获取权限');
                        return;
                    }
                    euvpService.setToken(result.data.token);
                    history.push('/selectSwitcher');
                    message.success('登录成功');
                }

            } else {
                await authService.login(username, password);
                history.push('/selectSwitcher')
                message.success('登录成功')
            }
        } catch (e) {
            message.error(`登录失败`);
        }
    }
}
