import './SelectSwitcher.scss';
import React from "react";
import { Select, Menu, Dropdown } from "antd";
import { SwitcherResponse } from "../../common/types";
import { switcherService } from "../../services/SwitcherService";
import { Link } from "react-router-dom";
import * as uuid from "uuid";
import { authService } from "../../services/AuthService";
import { history } from '../../common/history';

interface selectSwitcherProps {
    handleLogout: () => void;
}
interface selectSwitcherState {
    switchers: SwitcherResponse[],
    roomId?: string,
    username?: string,
}

const DEFAULT_CODE = uuid.v4();

export class SelectSwitcher extends React.Component<selectSwitcherProps, selectSwitcherState> {
    public constructor(props: selectSwitcherProps) {
        super(props);
        this.state = {
            switchers: [],
        }
    }

    public async componentDidMount(): Promise<void> {
        this.setState({
            switchers: await switcherService.getSwitchers(),
            username: authService.currentUser(),
        })
    }

    public render() {
        return (
            <div className='selectPage'>
                <div className='selectSwitcher'>
                    <Dropdown overlay={this.usermenu} trigger={['click']}>
                    <span style={{ cursor: 'pointer', display: 'inline-block', marginTop: '5px', marginLeft: '88%' }} onClick={e => e.preventDefault()}>
                        <b style={{ fontSize: '15px' }}>{this.state.username || ''}&nbsp;&nbsp;</b><i className="fas fa-chevron-down" style={{ marginRight: '10px' }} />
                    </span>
                    </Dropdown>
                    <div className='select'>
                        <Select
                            className='selects'
                            size={"large"}
                            placeholder={'请选择导播台'}
                            value={this.state.roomId}
                            onChange={(value: string) => this.handleChangeSwitcher(value)}
                        >
                            <Select.Option key={0} value={DEFAULT_CODE}>
                                其他
                            </Select.Option>
                            {this.state.switchers.map(s => (
                                <Select.Option key={s.id} value={s.code}>
                                    {s.name}
                                </Select.Option>
                            ))}
                        </Select>
                        {
                            this.state.roomId &&
                            <Link to={`/template/${this.state.roomId}`}>
                                <button>
                                    确认
                                </button>
                            </Link>
                        }
                    </div>
                </div>
            </div>
        );
    }

    private usermenu = (
        <Menu>
            <Menu.Item onClick={() => this.handleLogout()}>退出登录</Menu.Item>
        </Menu>
    );

    private handleChangeSwitcher(value: string): void {
        this.setState({
            roomId: value,
        })
    }

    private async handleLogout(): Promise<void> {
        await authService.logout();
        history.push('/advancecg');
    }
}
