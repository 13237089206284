import { SWITCHER_BASE_URL } from "../common/constants";
import axios from 'axios';
import { SwitcherResponse, SwitcherStatus } from "../common/types";

const GET_SWITCHERS_URL = `${SWITCHER_BASE_URL}/v1/switchers`;

class SwitcherService {
    public async getSwitchers (statuses: SwitcherStatus[] | undefined = undefined, hasUser: boolean = false, owner: string | undefined = undefined): Promise<SwitcherResponse[]> {
        return (await axios.get(GET_SWITCHERS_URL, {
            params: {
                ...statuses ? { statuses: statuses.join(',') } : {},
                ...hasUser ? { hasUser: true } : {},
            },
            headers: {
                ...owner ? { 'x-owner': '*' } : {},
            }
        })).data as SwitcherResponse[];
    }
}

export const switcherService = new SwitcherService();
