import React from 'react';
import { Image, Input, InputNumber, Slider, Row, Col, Form, Typography } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { io, Socket } from 'socket.io-client';
import { goBack, notNull } from '../../common/utils';
import { Preview } from '../preview/preview';
import { CommonRefreshData } from '../../common/types';
import './common.scss';
import logo from '../preview/img/logo.png';
import { CAPTION_BASE_URL, CAPTION_SHOW_URL } from '../../common/constants';
import { RouteComponentProps } from "react-router-dom";
import { history } from "../../common/history";

interface captionProps extends RouteComponentProps<{id: string}>{
}

interface captionState {
    score1: number;
    score2: number;
    team1: string;
    team2: string;
    display: boolean;
    transparency: number;
    captionTitle: string;
    caption1: string;
    caption2: string;
    showCaption: boolean;
    showScoreCard: boolean;
    showCountDown: boolean;
    showLogo: boolean;
    previewCaption: boolean;
    previewScoreCard: boolean;
    previewCountDown: boolean;
    previewLogo: boolean;
    minute: number;
    second: number;
    beginCountDown: boolean;
    logoUrl: string;
}

const SOCKET_PATH = '/captionserver/socket.io';
let socket: Socket;

export class Common extends React.Component<captionProps, captionState>{
    private time1Ref : React.RefObject<HTMLSpanElement> = React.createRef();
    private time2Ref : React.RefObject<HTMLSpanElement> = React.createRef();
    private scoreCardFormRef = React.createRef<FormInstance>();
    private logoUrlFormRef = React.createRef<FormInstance>();
    private captionFormRef = React.createRef<FormInstance>();
    private currentTime: number;
    public constructor(props: captionProps) {
        super(props);
        this.state = {
            score1: 0,
            score2: 0,
            team1: '',
            team2: '',
            display: false,
            transparency: 0,
            captionTitle: '',
            caption1: '',
            caption2: '',
            showCaption: false,
            showScoreCard: false,
            showCountDown: false,
            showLogo: false,
            previewCaption: false,
            previewScoreCard: false,
            previewCountDown: false,
            previewLogo: false,
            minute: 0,
            second: 0,
            beginCountDown: false,
            logoUrl: '',
        }
    }
    public async componentDidMount(): Promise<void> {
        document.title = '云导播-高级图文';
        this.currentTime = window.setInterval(() => {
            let date = new Date();
            notNull(this.time1Ref.current).innerHTML = date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日';
            notNull(this.time2Ref.current).innerHTML = date.getHours() + ':' + (date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes()) + ':' + (date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds());
        },1000);
        this.joinRoom();
        this.unauthorized();
        socket.on("refresh", (data) => {
            switch (data.type) {
                case 'finishCountDown':
                    this.finishCountDown();
                    break;
                case 'commonRefreshData':
                    this.refresh(data);
                    break;

            }
        });
        setTimeout(()=> {
            socket.emit("controller", {
                type: "listenCommon",
            });
        },1000)
        socket.emit("controller", {
            type: "showGolfIndividualCaption",
            display: false
        });
    }

    public componentWillUnmount() {
        clearInterval(this.currentTime);
    }

    private joinRoom(): void {
        socket = io(CAPTION_BASE_URL, {
            path: SOCKET_PATH,
            transports: ['websocket'],
            query: {
                roomId: this.props.match.params.id,
                token: sessionStorage.getItem('token') ? `Bearer ${sessionStorage.getItem('token')}` : null,
            }
        });
    }

    private unauthorized(): void {
        socket.on('unauthorized', () => {
            history.push('/advancecg');
        })
    }

    private setScoreCard(): void {
        socket.emit("controller", {
            type: "score",
            score1: this.state.score1,
            score2: this.state.score2,
            team1: this.state.team1,
            team2: this.state.team2,
        });
    }

    private previewScoreCard(): void {
        this.setState({
            previewScoreCard: !this.state.previewScoreCard
        }, () => {
            socket.emit("controller", {
                type: "previewScoreCard",
                display: this.state.previewScoreCard,
                score1: this.state.score1,
                score2: this.state.score2,
                team1: this.state.team1,
                team2: this.state.team2,
            });
        })
    }

    private showScoreCard(): void {
        this.setState({
            showScoreCard: !this.state.showScoreCard
        }, () => {
            socket.emit("controller", {
                type: "showScoreCard",
                display: this.state.showScoreCard,
                score1: this.state.score1,
                score2: this.state.score2,
                team1: this.state.team1,
                team2: this.state.team2,
            });
        })
    }

    private async setCaption(): Promise<void> {
        socket.emit("controller", {
            type: "caption",
            transparency: this.state.transparency,
            captionTitle: this.state.captionTitle,
            caption1: this.state.caption1,
            caption2: this.state.caption2,
        });
    }

    private async previewCaption(): Promise<void> {
        this.setState({
            previewCaption: !this.state.previewCaption
        }, () => {
            socket.emit("controller", {
                type: "previewCaption",
                display: this.state.previewCaption,
                transparency: this.state.transparency,
                captionTitle: this.state.captionTitle,
                caption1: this.state.caption1,
                caption2: this.state.caption2,
            });
        })
    }

    private async showCaption(): Promise<void> {
        this.setState({
            showCaption: !this.state.showCaption
        }, () => {
            socket.emit("controller", {
                type: "showCaption",
                display: this.state.showCaption,
                transparency: this.state.transparency,
                captionTitle: this.state.captionTitle,
                caption1: this.state.caption1,
                caption2: this.state.caption2,
            });
        })
    }

    private setCountDown(): void {
        socket.emit("controller", {
            type: "countDown",
            minute: this.state.minute,
            second: this.state.second,
        });
    }

    private beginCountDown(): void {
        this.setState({
            beginCountDown: !this.state.beginCountDown
        },() => {
            socket.emit("controller", {
                type: "beginCountDown",
                display: this.state.beginCountDown,
                minute: this.state.minute,
                second: this.state.second,
            });
        })
    }

    private finishCountDown() {
        this.setState({
            beginCountDown: false
        })
    }

    private previewCountDown(): void {
        this.setState({
            previewCountDown: !this.state.previewCountDown
        }, () => {
            socket.emit("controller", {
                type: "previewCountDown",
                display: this.state.previewCountDown,
                minute: this.state.minute,
                second: this.state.second,
            });
        })
    }

    private showCountDown(): void {
        this.setState({
            showCountDown: !this.state.showCountDown
        }, () => {
            socket.emit("controller", {
                type: "showCountDown",
                display: this.state.showCountDown,
                minute: this.state.minute,
                second: this.state.second,
            });
        })
    }

    private setLogo(): void {
        socket.emit("controller", {
            type: "logoUrl",
            logoUrl: this.state.logoUrl,
        });
    }

    private previewLogo(): void {
        this.setState({
            previewLogo: !this.state.previewLogo
        }, () => {
            socket.emit("controller", {
                type: "previewLogo",
                display: this.state.previewLogo,
                logoUrl: this.state.logoUrl
            });
        })
    }

    private showLogo(): void {
        this.setState({
            showLogo: !this.state.showLogo
        }, () => {
            socket.emit("controller", {
                type: "showLogo",
                display: this.state.showLogo,
                logoUrl: this.state.logoUrl
            });
        })
    }

    private refresh(refreshData: CommonRefreshData): void {
        this.setState({
            score1: refreshData.score1,
            score2: refreshData.score2,
            team1: refreshData.team1,
            team2: refreshData.team2,
            display: refreshData.display,
            transparency: refreshData.transparency,
            captionTitle: refreshData.captionTitle,
            caption1: refreshData.caption1,
            caption2: refreshData.caption2,
            showCaption: refreshData.showCaption,
            showScoreCard: refreshData.showScoreCard,
            showCountDown: refreshData.showCountDown,
            showLogo: refreshData.showLogo,
            previewCaption: refreshData.showCaption,
            previewScoreCard: refreshData.showScoreCard,
            previewCountDown: refreshData.showCountDown,
            previewLogo: refreshData.showLogo,
            minute: refreshData.minute,
            second: refreshData.second,
            beginCountDown: refreshData.beginCountDown,
            logoUrl: refreshData.logoUrl,
        }, () => {
            this.scoreCardFormRef.current?.setFieldsValue({
                team1: refreshData.team1,
                team2: refreshData.team2,
            });
            this.logoUrlFormRef.current?.setFieldsValue({
                logoUrl: refreshData.logoUrl,
            })
            this.captionFormRef.current?.setFieldsValue({
                captionTitle: refreshData.captionTitle,
                caption1: refreshData.caption1,
                caption2: refreshData.caption2,
            })
        })
    }

    public render(): JSX.Element {
        return (
            <div className='captionHome'>
                <div className='top'>
                    <div className='topContent'>
                        <i title={'返回上一级'} className={'back fas fa-arrow-left'} onClick={() => goBack()}/>
                        <img className='logo' alt={'logo'} src={logo}/>
                        <Typography.Paragraph
                            className='copy'
                            copyable={{
                                text: `${CAPTION_SHOW_URL}/captionShow?id=${this.props.match.params.id}`,
                                icon: [<button className='iconButton'>复制输出地址</button>],
                                tooltips: ['复制', '复制成功']}}/>
                    </div>
                </div>
                <div className='previewDiv'>
                    <div className='previewContentDiv'>
                        <div className='previewTop'>
                            <div className='topDiv'>
                                <span>画面预览</span>
                                <span ref={this.time1Ref}/>
                                <span ref={this.time2Ref}/>
                            </div>
                        </div>
                        <div className='preview'>
                            <Preview
                                roomId={this.props.match.params.id}
                            />
                        </div>
                    </div>
                </div>
                <div className='controller'>
                    <Form
                        ref={this.scoreCardFormRef}
                        className='scoreCardDiv'>
                        <div className='scoreCard'>
                            <p>比分牌</p>
                        </div>
                        <div className='scoreCardController'>
                            <div className='aTeam'>
                                <p style={{textAlign: 'center', fontWeight: 'bold', marginTop: '10px'}}>A队</p>
                                <div style={{display: 'flex', float: 'left'}}>
                                    <p style={{margin: '5px 10px auto 24px'}}>队名：</p>
                                    <Form.Item
                                        name="team1"
                                        style={{width: '50%'}}
                                        rules={[{ max: 10, message: '队名不超过10个字' }]}
                                    >
                                        <Input
                                            placeholder={'请输入队名'}
                                            value={this.state.team1}
                                            maxLength={10}
                                            bordered={false}
                                            onChange={(e) => this.setState({ team1: e.target.value })}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{display: 'flex', float: 'left'}}>
                                    <p style={{margin: 'auto 10px auto 24px'}}>比分：</p>
                                    <InputNumber
                                        min={0}
                                        max={1000}
                                        precision={0}
                                        bordered={false}
                                        value={this.state.score1}
                                        style={{width: '50%'}}
                                        onChange={(value: number) => this.setState({ score1: value === null ? 0 : value })}
                                    />
                                </div>
                            </div>
                            <div className='bTeam'>
                                <p style={{textAlign: 'center', fontWeight: 'bold', marginTop: '10px'}}>B队</p>
                                <div style={{display: 'flex', float: 'left'}}>
                                    <p style={{margin: '5px 10px auto 24px'}}>队名：</p>
                                    <Form.Item
                                        name="team2"
                                        style={{width: '50%'}}
                                        rules={[{ max: 10, message: '队名不超过10个字' }]}
                                    >
                                        <Input
                                            placeholder={'请输入队名'}
                                            value={this.state.team2}
                                            maxLength={10}
                                            bordered={false}
                                            onChange={(e) => this.setState({ team2: e.target.value })}
                                        />
                                    </Form.Item>
                                </div>
                                <div style={{display: 'flex', float: 'left'}}>
                                    <p style={{margin: 'auto 10px auto 24px'}}>比分：</p>
                                    <InputNumber
                                        min={0}
                                        max={1000}
                                        precision={0}
                                        bordered={false}
                                        value={this.state.score2}
                                        style={{width: '50%'}}
                                        onChange={(value: number) => this.setState({ score2: value === null ? 0 : value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'flex-end', marginTop: '48px'}}>
                            <button onClick={() => this.previewScoreCard()} style={{background: `${this.state.previewScoreCard ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>{this.state.previewScoreCard ? '隐藏' : '预览'}</button>
                            <button onClick={() => this.showScoreCard()} style={{background: `${this.state.showScoreCard ? '#4F5E65' : '#05A905'}`, marginLeft: '20px', borderRadius: '6px'}}>{this.state.showScoreCard ? '隐藏' : '输出'}</button>
                            <button onClick={() => this.setScoreCard()} style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>刷新</button>
                        </div>
                    </Form>
                    <Form
                        className='countDownDiv'>
                        <div className='countDown'>
                            <p>倒计时</p>
                        </div>
                        <div className='countDownController'>
                            <div style={{width: '100%'}}>
                                <div style={{alignItems: 'center', display: 'flex', float: 'left', width: '100%', marginTop: '60px', marginLeft: '10px'}}>
                                    <p style={{margin: 'auto 8px'}}>时长</p>
                                    <InputNumber
                                        style={{width: '20%'}}
                                        value={this.state.minute}
                                        bordered={false}
                                        precision={0}
                                        onChange={(value: number) => this.setState({ minute: value === null ? 0 : (this.state.second > 0 && value === 120) ? 119 : value })}
                                        min={0}
                                        max={120}
                                    />
                                    <p style={{margin: 'auto 8px'}}>分</p>
                                    <InputNumber
                                        style={{width: '20%'}}
                                        value={this.state.second}
                                        bordered={false}
                                        precision={0}
                                        onChange={(value: number) => this.setState({ second: (value === null || this.state.minute === 120) ? 0 : value })}
                                        min={0}
                                        max={59}
                                    />
                                    <p style={{margin: 'auto 8px'}}>秒</p>
                                    <button style={{margin: 'auto 8px', background: `${this.state.beginCountDown ? '#4F5E65' : '#F85640'}`, borderRadius: '6px'}} onClick={() => this.beginCountDown()}>
                                        {this.state.beginCountDown ? '暂停' : '开始'}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'flex-end', marginTop: '33px'}}>
                            <button onClick={() => this.previewCountDown()} style={{background: `${this.state.previewCountDown ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                                {this.state.previewCountDown ? '隐藏' : '预览'}
                            </button>
                            <button onClick={() => this.showCountDown()} style={{background: `${this.state.showCountDown ? '#4F5E65' : '#05A905'}`, marginLeft: '20px', borderRadius: '6px'}}>
                                {this.state.showCountDown ? '隐藏' : '输出'}
                            </button>
                            <button onClick={() => this.setCountDown()} style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>刷新</button>
                        </div>
                    </Form>
                    <Form
                        ref={this.logoUrlFormRef}
                        className='logoUrlDiv'>
                        <div className='logoUrl'>
                            <p>角标</p>
                        </div>
                        <div className='logoUrlController'>
                            <div>
                                <div style={{marginTop: '40px', width: '85%'}}>
                                    <p style={{display: 'flex', float: 'left', margin: 'auto 20px'}}>图片地址：</p>
                                    <Form.Item
                                        name='logoUrl'
                                        validateStatus="warning"
                                        help={'建议格式：*.png;*.jpeg;*.jpg;*.bmp'}
                                    >
                                        <Input
                                            value={this.state.logoUrl}
                                            placeholder={'请输入图片链接地址'}
                                            bordered={false}
                                            onChange={(e) => this.setState({ logoUrl: e.target.value })}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <p style={{display: 'flex', float: 'left', margin: '20px'}}>图片预览：</p>
                                    <Image
                                        style={{marginTop: '20px'}}
                                        width={80}
                                        src={this.state.logoUrl}
                                        fallback={logo}
                                        preview={false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'flex-end', marginTop: '8px'}}>
                            <button onClick={() => this.previewLogo()} style={{background: `${this.state.previewLogo ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>{this.state.previewLogo ? '隐藏' : '预览'}</button>
                            <button onClick={() => this.showLogo()} style={{background: `${this.state.showLogo ? '#4F5E65' : '#05A905'}`, marginLeft: '20px', borderRadius: '6px'}}>{this.state.showLogo ? '隐藏' : '输出'}</button>
                            <button onClick={() => this.setLogo()} style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>刷新</button>
                        </div>
                    </Form>
                    <Form
                        ref={this.captionFormRef}
                        className='captionDiv'>
                        <div className='caption'>
                            <p>字幕条</p>
                        </div>
                        <div className='captionController'>
                            <div style={{width: '100%', height: '100%'}}>
                                <div style={{paddingTop: '10px'}}>
                                    <p style={{margin: '3px 33px auto 20px', display: 'flex', float: 'left'}}>标题：</p>
                                    <Form.Item
                                        name="captionTitle"
                                        rules={[{ required: true, message: '确认为空？', warningOnly: true },
                                                { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                                { max: 4, message: '标题不超过4个字' }]}
                                    >
                                        <Input
                                            placeholder={'不超过4个字'}
                                            value={this.state.captionTitle}
                                            style={{width: '90%'}}
                                            bordered={false}
                                            maxLength={4}
                                            onChange={(e) => this.setState({ captionTitle: e.target.value })}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <p style={{margin: '3px 25px auto 20px', display: 'flex', float: 'left'}}>文字1：</p>
                                    <Form.Item
                                        name="caption1"
                                        rules={[{ required: true, message: '确认为空？', warningOnly: true },
                                                { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                                { max: 14, message: '文字1不超过14个字' }]}
                                    >
                                        <Input
                                            placeholder={'不超过14个字'}
                                            value={this.state.caption1}
                                            style={{width: '90%'}}
                                            bordered={false}
                                            maxLength={14}
                                            onChange={(e) => this.setState({ caption1: e.target.value })}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <p style={{margin: '3px 25px auto 20px', display: 'flex', float: 'left'}}>文字2：</p>
                                    <Form.Item
                                        name="caption2"
                                        rules={[{ required: true, message: '确认为空？', warningOnly: true },
                                                { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                                { max: 100, message: '文字2不超过100个字' }]}
                                    >
                                        <Input
                                            placeholder={'不超过100个字'}
                                            value={this.state.caption2}
                                            style={{width: '90%'}}
                                            bordered={false}
                                            maxLength={100}
                                            onChange={(e) => this.setState({ caption2: e.target.value })}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <p style={{margin: '3px 25px auto 20px', display: 'flex', float: 'left'}}>透明度：</p>
                                    <Row style={{width: '60%'}}>
                                        <Col span={15}>
                                            <Slider
                                                min={0}
                                                max={100}
                                                onChange={(value: number) => this.setState({transparency: value > 100 ? 100 : value < 0 ? 0 : value})}
                                                value={this.state.transparency}
                                            />
                                        </Col>
                                        <Col span={3}>
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                precision={0}
                                                bordered={false}
                                                style={{margin: '0 5px'}}
                                                value={this.state.transparency}
                                                onChange={value => this.setState({transparency: value === null ? 0 : value > 100 ? 100 : value < 0 ? 0 : value})}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'flex-end', marginTop: '16px'}}>
                            <button onClick={() => this.previewCaption()} style={{background: `${this.state.previewCaption ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>{this.state.previewCaption ? '隐藏' : '预览'}</button>
                            <button onClick={() => this.showCaption()} style={{background: `${this.state.showCaption ? '#4F5E65' : '#05A905'}`, marginLeft: '20px', borderRadius: '6px'}}>{this.state.showCaption ? '隐藏' : '输出'}</button>
                            <button onClick={() => this.setCaption()} style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>刷新</button>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }

}
