import React from 'react';
import './BasketballCommentary.scss';
import { Form, Input } from "antd";
import {FormInstance} from "antd/es/form";

interface BasketballCommentaryProps {
    formRef: React.RefObject<FormInstance>;
    commentator: string;
    guest: string;
    venue: string;
    previewCommentary: boolean;
    showCommentary: boolean;
    handCommentatorChange: (commentator: string) => void;
    handGuestChange: (guest: string) => void;
    handVenueChange: (venue: string) => void;
    show: () => void;
    preview: () => void;
    set: () => void;
}
export class BasketballCommentary extends React.Component<BasketballCommentaryProps, unknown>{

    public render(): JSX.Element {
        return (
            <Form
                ref={this.props.formRef}
                className='basketballCommentary'>
                <div className='basketballCommentaryDiv'>
                    <p>解说/嘉宾/场馆</p>
                </div>
                <div style={{width: '100%', height: '100%'}}>
                    <div style={{paddingTop: '1px'}}>
                        <p style={{margin: '1px 25px auto 20px', display: 'flex', float: 'left'}}>解说：</p>
                        <Form.Item
                            name="commentator"
                            rules={[{ max: 10, message: '解说不超过10个字' }]}
                        >
                            <Input
                                placeholder={'不超过10个字'}
                                value={this.props.commentator}
                                style={{width: '90%', background: '#17242D', color: '#FFFFFF'}}
                                bordered={false}
                                maxLength={10}
                                onChange={(e) => this.props.handCommentatorChange(e.target.value)}
                            />
                        </Form.Item>
                    </div>
                    <div style={{paddingTop: '1px'}}>
                        <p style={{margin: '1px 25px auto 20px', display: 'flex', float: 'left'}}>嘉宾：</p>
                        <Form.Item
                            name="guest"
                            rules={[{ max: 10, message: '嘉宾不超过10个字' }]}
                        >
                            <Input
                                placeholder={'不超过10个字'}
                                value={this.props.guest}
                                style={{width: '90%', background: '#17242D', color: '#FFFFFF'}}
                                bordered={false}
                                maxLength={10}
                                onChange={(e) => this.props.handGuestChange(e.target.value)}
                            />
                        </Form.Item>
                    </div>
                    <div style={{paddingTop: '1px'}}>
                        <p style={{margin: '1px 25px auto 20px', display: 'flex', float: 'left'}}>场馆：</p>
                        <Form.Item
                            name="venue"
                            rules={[{ max: 20, message: '场馆不超过20个字' }]}
                        >
                            <Input
                                placeholder={'不超过20个字'}
                                value={this.props.venue}
                                style={{width: '90%', background: '#17242D', color: '#FFFFFF'}}
                                bordered={false}
                                maxLength={20}
                                onChange={(e) => this.props.handVenueChange(e.target.value)}
                            />
                        </Form.Item>
                    </div>
                    <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: '3px'}}>
                        <button onClick={() => this.props.preview()}
                                style={{background: `${this.props.previewCommentary ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                            {this.props.previewCommentary ? '隐藏' : '预览'}
                        </button>
                        <button onClick={() => this.props.show()}
                                style={{background: `${this.props.showCommentary ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '20px'}}>
                            {this.props.showCommentary ? '隐藏' : '输出'}
                        </button>
                        <button onClick={() => this.props.set()}
                                style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                            刷新
                        </button>
                    </div>
                </div>
            </Form>
        );
    }
}
