import React from 'react';
import './BasketballScoreCard.scss';
import { Form, Input, InputNumber } from "antd";
import { FormInstance } from "antd/es/form";

interface basketballScoreCardProps {
    formRef: React.RefObject<FormInstance>;
    name1: string;
    name2: string;
    score1: number;
    score2: number;
    previewBasketballScoreCard: boolean;
    showBasketballScoreCard: boolean;
    addScore1: (increment: number) => void;
    addScore2: (increment: number) => void;
    show: () => void;
    preview: () => void;
    set: () => void;
    handChangeScore1: (score: number) => void;
    handChangeScore2: (score: number) => void;
    handChangeName1: (name: string) => void;
    handChangeName2: (name: string) => void;
}
export class BasketballScoreCard extends React.Component<basketballScoreCardProps, unknown>{

    public render(): JSX.Element {
        return (
            <Form
                ref={this.props.formRef}
                className='scoreCardDiv'>
                <div className='scoreCard'>
                    <p>比分牌</p>
                </div>
                <div className='scoreCardController'>
                    <div className='aTeam'>
                        <div style={{display: 'flex', float: 'left'}}>
                            <p style={{margin: '5px 5px auto 7px'}}>主队：</p>
                            <Form.Item
                                name="name1"
                                style={{width: '73%'}}
                                rules={[
                                    { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                    { max: 10, message: '队名不超过10个字' }]}
                            >
                                <Input
                                    placeholder={'不超过10个字'}
                                    value={this.props.name1}
                                    maxLength={10}
                                    bordered={false}
                                    onChange={(e) => this.props.handChangeName1(e.target.value)}
                                />
                            </Form.Item>
                        </div>
                        <div style={{display: 'flex', float: 'left'}}>
                            <p style={{margin: 'auto 5px auto 7px'}}>比分：</p>
                            <InputNumber
                                min={0}
                                max={999}
                                precision={0}
                                bordered={false}
                                value={this.props.score1}
                                style={{width: '30%'}}
                                onChange={(value: number) => this.props.handChangeScore1(value === null ? 0 : value)}
                            />
                            <div className='circle' onClick={() => this.props.addScore1(1)}>
                                +1
                            </div>
                            <div className='circle' onClick={() => this.props.addScore1(2)}>
                                +2
                            </div>
                            <div className='circle' onClick={() => this.props.addScore1(3)}>
                                +3
                            </div>
                        </div>
                    </div>
                    <div className='bTeam'>
                        <div style={{display: 'flex', float: 'left'}}>
                            <p style={{margin: '5px 5px auto 7px'}}>客队：</p>
                            <Form.Item
                                name="name2"
                                style={{width: '73%'}}
                                rules={[
                                    { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                    { max: 10, message: '队名不超过10个字' }]}
                            >
                                <Input
                                    placeholder={'不超过10个字'}
                                    value={this.props.name2}
                                    maxLength={10}
                                    bordered={false}
                                    onChange={(e) => this.props.handChangeName2(e.target.value)}
                                />
                            </Form.Item>
                        </div>
                        <div style={{display: 'flex', float: 'left'}}>
                            <p style={{margin: 'auto 5px auto 7px'}}>比分：</p>
                            <InputNumber
                                min={0}
                                max={999}
                                precision={0}
                                bordered={false}
                                value={this.props.score2}
                                style={{width: '30%'}}
                                onChange={(value: number) => this.props.handChangeScore2(value === null ? 0 : value)}
                            />
                            <div className='circle' onClick={() => this.props.addScore2(1)}>
                                +1
                            </div>
                            <div className='circle' onClick={() => this.props.addScore2(2)}>
                                +2
                            </div>
                            <div className='circle' onClick={() => this.props.addScore2(3)}>
                                +3
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: '33px'}}>
                    <button onClick={() => this.props.preview()}
                            style={{background: `${this.props.previewBasketballScoreCard ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                        {this.props.previewBasketballScoreCard ? '隐藏' : '预览'}
                    </button>
                    <button onClick={() => this.props.show()}
                            style={{background: `${this.props.showBasketballScoreCard ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '20px'}}>
                        {this.props.showBasketballScoreCard ? '隐藏' : '输出'}
                    </button>
                    <button onClick={() => this.props.set()}
                            style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                        刷新
                    </button>
                </div>
            </Form>
        );
    }
}
