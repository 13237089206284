import { Socket } from 'socket.io-client';
import axios from "axios";
import { CAPTION_BASE_URL } from "../common/constants";
import { Player, Player8 } from "../common/types";
const GET_PLAYERS_URL = `${CAPTION_BASE_URL}/captionserver/v1/players`;

class GolfService {
    public async getPlayers(xmlUrl: string): Promise<Player[] | undefined> {
        return (await axios.post(GET_PLAYERS_URL, { xmlUrl })).data as Player[];
    }

    public cleanAdvanceCG(socket: Socket): void {
        socket.emit('controller', {
            type: 'cleanAdvanceCG',
        })
    }

    public showGrade(socket: Socket): void {
        socket.emit("controller", {
            type: "showGrade",
        });
    }

    public setGrade(socket: Socket): void {
        socket.emit("controller", {
            type: "setGrade",
        });
    }

    public showGolfIndividualCaption(socket: Socket, display: boolean, playerName: string, currentHole: number, golfScore: number | undefined, currentPar: number, currentStrokes: number, yds: number, forBall: string): void {
        socket.emit("controller", {
            type: "showGolfIndividualCaption",
            display: display,
            playerName: playerName,
            currentHole: currentHole,
            golfScore: golfScore,
            currentPar: currentPar,
            currentStrokes: currentStrokes,
            yds: yds,
            forBall: forBall
        });
    }

    public previewGolfIndividualCaption(socket: Socket, display: boolean, playerName: string, currentHole: number, golfScore: number | undefined, currentPar: number, currentStrokes: number, yds: number, forBall: string): void {
        socket.emit("controller", {
            type: "previewGolfIndividualCaption",
            display: display,
            playerName: playerName,
            currentHole: currentHole,
            golfScore: golfScore,
            currentPar: currentPar,
            currentStrokes: currentStrokes,
            yds: yds,
            forBall: forBall
        });
    }

    public setGolfIndividualCaption(socket: Socket, playerName: string, currentHole: number, golfScore: number | undefined, currentPar: number, currentStrokes: number, yds: number, forBall: string): void {
        socket.emit("controller", {
            type: "setGolfIndividualCaption",
            playerName: playerName,
            currentHole: currentHole,
            golfScore: golfScore,
            currentPar: currentPar,
            currentStrokes: currentStrokes,
            yds: yds,
            forBall: forBall
        });
    }

    public showGolfLogo(socket: Socket, display: boolean, logoUrl: string): void {
        socket.emit('controller', {
            type: 'showGolfLogo',
            display: display,
            logoUrl: logoUrl
        })
    }

    public previewGolfLogo(socket: Socket, display: boolean, logoUrl: string): void {
        socket.emit('controller', {
            type: 'previewGolfLogo',
            display: display,
            logoUrl: logoUrl
        })
    }

    public setGolfLogo(socket: Socket, logoUrl: string): void {
        socket.emit('controller', {
            type: 'setGolfLogo',
            logoUrl: logoUrl
        })
    }

    public showGolfCaption(socket: Socket, display: boolean, golfCaptionTitle: string, golfCaption1: string, golfTransparency: number): void {
        socket.emit('controller', {
            type: 'showGolfCaption',
            display: display,
            golfCaptionTitle: golfCaptionTitle,
            golfCaption1: golfCaption1,
            golfTransparency: golfTransparency
        })
    }

    public previewGolfCaption(socket: Socket, display: boolean, golfCaptionTitle: string, golfCaption1: string, golfTransparency: number): void {
        socket.emit('controller', {
            type: 'previewGolfCaption',
            display: display,
            golfCaptionTitle: golfCaptionTitle,
            golfCaption1: golfCaption1,
            golfTransparency: golfTransparency
        })
    }

    public setGolfCaption(socket: Socket, golfCaptionTitle: string, golfCaption1: string, golfTransparency: number): void {
        socket.emit('controller', {
            type: 'setGolfCaption',
            golfCaptionTitle: golfCaptionTitle,
            golfCaption1: golfCaption1,
            golfTransparency: golfTransparency
        })
    }

    public showGolfWeather(socket: Socket, display: boolean, golfWeather:string, golfTemperature: number, golfWind: string, golfWindDirection: string): void {
        socket.emit('controller', {
            type: 'showWeather',
            display: display,
            golfWeather: golfWeather,
            golfTemperature: golfTemperature,
            golfWind: golfWind,
            golfWindDirection: golfWindDirection
        })
    }

    public previewGolfWeather(socket: Socket, display: boolean, golfWeather:string, golfTemperature: number, golfWind: string, golfWindDirection: string): void {
        socket.emit('controller', {
            type: 'previewWeather',
            display: display,
            golfWeather: golfWeather,
            golfTemperature: golfTemperature,
            golfWind: golfWind,
            golfWindDirection: golfWindDirection
        })
    }

    public setGolfWeather(socket: Socket, golfWeather:string, golfTemperature: number, golfWind: string, golfWindDirection: string): void {
        socket.emit('controller', {
            type: 'setWeather',
            golfWeather: golfWeather,
            golfTemperature: golfTemperature,
            golfWind: golfWind,
            golfWindDirection: golfWindDirection
        })
    }

    public showGolfCurrentRank(socket:Socket, state: any): void {
        socket.emit('controller', {
            type: 'showGolfCurrentRank',
            display: state.showGolfCurrentRank,
            position1: state.position1,
            position2: state.position2,
            position3: state.position3,
            position4: state.position4,
            position5: state.position5,
            position6: state.position6,
            name1: state.name1,
            name2: state.name2,
            name3: state.name3,
            name4: state.name4,
            name5: state.name5,
            name6: state.name6,
            golfScore1: state.golfScore1,
            golfScore2: state.golfScore2,
            golfScore3: state.golfScore3,
            golfScore4: state.golfScore4,
            golfScore5: state.golfScore5,
            golfScore6: state.golfScore6,
            thru1: state.thru1,
            thru2: state.thru2,
            thru3: state.thru3,
            thru4: state.thru4,
            thru5: state.thru5,
            thru6: state.thru6,
            today1: state.today1,
            today2: state.today2,
            today3: state.today3,
            today4: state.today4,
            today5: state.today5,
            today6: state.today6,
            showGolfCurrentPlayer: state.showGolfCurrentPlayer,
        })
    }

    public previewGolfCurrentRank(socket:Socket, state: any): void {
        socket.emit('controller', {
            type: 'previewGolfCurrentRank',
            display: state.previewGolfCurrentRank,
            position1: state.position1,
            position2: state.position2,
            position3: state.position3,
            position4: state.position4,
            position5: state.position5,
            position6: state.position6,
            name1: state.name1,
            name2: state.name2,
            name3: state.name3,
            name4: state.name4,
            name5: state.name5,
            name6: state.name6,
            golfScore1: state.golfScore1,
            golfScore2: state.golfScore2,
            golfScore3: state.golfScore3,
            golfScore4: state.golfScore4,
            golfScore5: state.golfScore5,
            golfScore6: state.golfScore6,
            thru1: state.thru1,
            thru2: state.thru2,
            thru3: state.thru3,
            thru4: state.thru4,
            thru5: state.thru5,
            thru6: state.thru6,
            today1: state.today1,
            today2: state.today2,
            today3: state.today3,
            today4: state.today4,
            today5: state.today5,
            today6: state.today6,
            showGolfCurrentPlayer: state.showGolfCurrentPlayer,
        })
    }

    public setGolfCurrentRank(socket: Socket, state: any): void {
        socket.emit('controller', {
            type: 'setGolfCurrentRank',
            position1: state.position1,
            position2: state.position2,
            position3: state.position3,
            position4: state.position4,
            position5: state.position5,
            position6: state.position6,
            name1: state.name1,
            name2: state.name2,
            name3: state.name3,
            name4: state.name4,
            name5: state.name5,
            name6: state.name6,
            golfScore1: state.golfScore1,
            golfScore2: state.golfScore2,
            golfScore3: state.golfScore3,
            golfScore4: state.golfScore4,
            golfScore5: state.golfScore5,
            golfScore6: state.golfScore6,
            thru1: state.thru1,
            thru2: state.thru2,
            thru3: state.thru3,
            thru4: state.thru4,
            thru5: state.thru5,
            thru6: state.thru6,
            today1: state.today1,
            today2: state.today2,
            today3: state.today3,
            today4: state.today4,
            today5: state.today5,
            today6: state.today6,
            showGolfCurrentPlayer: state.showGolfCurrentPlayer,
        })
    }

    public showGolfPersonalAchievement(socket: Socket, state:any): void {
        socket.emit('controller', {
            type: 'showGolfPersonalAchievement',
            display: state.showGolfPersonalAchievement,
            playerName: state.playerName,
            golfScore: state.golfScore,
            hole1: state.hole1,
            hole2: state.hole2,
            hole3: state.hole3,
            hole4: state.hole4,
            hole5: state.hole5,
            hole6: state.hole6,
            hole7: state.hole7,
            hole8: state.hole8,
            hole9: state.hole9,
            holeTotal9: state.holeTotal9,
            hole10: state.hole10,
            hole11: state.hole11,
            hole12: state.hole12,
            hole13: state.hole13,
            hole14: state.hole14,
            hole15: state.hole15,
            hole16: state.hole16,
            hole17: state.hole17,
            hole18: state.hole18,
            holeTotal18: state.holeTotal18,
        })
    }

    public previewGolfPersonalAchievement(socket: Socket, state:any): void {
        socket.emit('controller', {
            type: 'previewGolfPersonalAchievement',
            display: state.previewGolfPersonalAchievement,
            playerName: state.playerName,
            golfScore: state.golfScore,
            hole1: state.hole1,
            hole2: state.hole2,
            hole3: state.hole3,
            hole4: state.hole4,
            hole5: state.hole5,
            hole6: state.hole6,
            hole7: state.hole7,
            hole8: state.hole8,
            hole9: state.hole9,
            holeTotal9: state.holeTotal9,
            hole10: state.hole10,
            hole11: state.hole11,
            hole12: state.hole12,
            hole13: state.hole13,
            hole14: state.hole14,
            hole15: state.hole15,
            hole16: state.hole16,
            hole17: state.hole17,
            hole18: state.hole18,
            holeTotal18: state.holeTotal18,
        })
    }

    public setGolfPersonalAchievement(socket: Socket, state:any): void {
        socket.emit('controller', {
            type: 'setGolfPersonalAchievement',
            playerName: state.playerName,
            golfScore: state.golfScore,
            hole1: state.hole1,
            hole2: state.hole2,
            hole3: state.hole3,
            hole4: state.hole4,
            hole5: state.hole5,
            hole6: state.hole6,
            hole7: state.hole7,
            hole8: state.hole8,
            hole9: state.hole9,
            holeTotal9: state.holeTotal9,
            hole10: state.hole10,
            hole11: state.hole11,
            hole12: state.hole12,
            hole13: state.hole13,
            hole14: state.hole14,
            hole15: state.hole15,
            hole16: state.hole16,
            hole17: state.hole17,
            hole18: state.hole18,
            holeTotal18: state.holeTotal18,
        })
    }

    public showGolfScoreList(socket: Socket, showGolfScoreList: boolean, players8: Player8[], golfCaptionTitle: string, golfCaption1: string, currentRound: number, roundName: string): void {
        socket.emit('controller', {
            type: 'showGolfScoreList',
            display: showGolfScoreList,
            players8: players8,
            golfCaptionTitle: golfCaptionTitle,
            golfCaption1: golfCaption1,
            currentRound: currentRound,
            roundName: roundName,
        })
    }

    public previewGolfScoreList(socket: Socket, showGolfScoreList: boolean, players8: Player8[], golfCaptionTitle: string, golfCaption1: string, currentRound: number, roundName: string): void {
        socket.emit('controller', {
            type: 'previewGolfScoreList',
            display: showGolfScoreList,
            players8: players8,
            golfCaptionTitle: golfCaptionTitle,
            golfCaption1: golfCaption1,
            currentRound: currentRound,
            roundName: roundName,
        })
    }

    public setGolfScoreList(socket: Socket, players8: Player8[], golfCaptionTitle: string, golfCaption1: string, currentRound: number, roundName: string): void {
        socket.emit('controller', {
            type: 'setGolfScoreList',
            players8: players8,
            golfCaptionTitle: golfCaptionTitle,
            golfCaption1: golfCaption1,
            currentRound: currentRound,
            roundName: roundName,
        })
    }
}
export const golfService = new GolfService();
