import React from 'react';
import './BasketballCaption.scss';
import { Form, Input } from "antd";
import {FormInstance} from "antd/es/form";

interface BasketballCaptionProps {
    formRef: React.RefObject<FormInstance>;
    title: string;
    text: string;
    previewBasketballCaption: boolean;
    showBasketballCaption: boolean;
    handTitleChange: (title: string) => void;
    handTextChange: (text: string) => void;
    show: () => void;
    preview: () => void;
    set: () => void;
}

export class BasketballCaption extends React.Component<BasketballCaptionProps, unknown>{

    public render(): JSX.Element {
        return (
            <Form
                ref={this.props.formRef}
                className='captionDiv'>
                <div className='caption'>
                    <p>新闻字幕条</p>
                </div>
                <div className='captionController'>
                    <div style={{width: '100%', height: '100%'}}>
                        <div style={{paddingTop: '10px'}}>
                            <p style={{margin: '3px 25px auto 20px', display: 'flex', float: 'left'}}>标题：</p>
                            <Form.Item
                                name="captionTitle"
                                rules={[{ max: 20, message: '标题不超过20个字' }]}
                            >
                                <Input
                                    placeholder={'不超过20个字'}
                                    value={this.props.title}
                                    style={{width: '90%'}}
                                    bordered={false}
                                    maxLength={20}
                                    onChange={(e) => this.props.handTitleChange(e.target.value)}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <p style={{margin: '3px 25px auto 20px', display: 'flex', float: 'left'}}>文字：</p>
                            <Form.Item
                                name="captionText"
                                rules={[{ max: 20, message: '文字不超过20个字' }]}
                            >
                                <Input
                                    placeholder={'不超过20个字'}
                                    value={this.props.text}
                                    style={{width: '90%'}}
                                    bordered={false}
                                    maxLength={20}
                                    onChange={(e) => this.props.handTextChange(e.target.value)}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: '33px'}}>
                    <button onClick={() => this.props.preview()}
                            style={{background: `${this.props.previewBasketballCaption ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                        {this.props.previewBasketballCaption ? '隐藏' : '预览'}
                    </button>
                    <button onClick={() => this.props.show()}
                            style={{background: `${this.props.showBasketballCaption ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '20px'}}>
                        {this.props.showBasketballCaption ? '隐藏' : '输出'}
                    </button>
                    <button onClick={() => this.props.set()}
                            style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                        刷新
                    </button>
                </div>
            </Form>
        );
    }
}
