import React, {RefObject} from 'react';
import { Select, Input, InputNumber, Form, Table, Typography, Image, Switch, Space, Modal, message } from 'antd';
import './golf.scss';
import {GolfRefreshData, Player, Player8, Score} from "../../common/types";
import { io, Socket } from 'socket.io-client';
import { golfService } from "../../services/GolfService";
import logo from "../preview/img/logo.png";
import { CAPTION_BASE_URL, CAPTION_SHOW_URL } from "../../common/constants";
import { Preview } from "../preview/preview";
import {goBack, notNull} from "../../common/utils";
import { FormInstance } from "antd/es/form";
import { RouteComponentProps } from "react-router-dom";
import { history } from "../../common/history";

interface golfProps extends RouteComponentProps<{id: string}>{
}

interface golfState {
    xmlUrl: string;
    showXmlUrl: boolean;
    saving: boolean;
    totalScore: Score[];
    showGolfIndividualCaption: boolean;
    showGolfLogo: boolean;
    golfLogoUrl: string;
    golfCaptionTitle: string;
    golfCaption1: string;
    golfTransparency: number;
    showGolfCaption: boolean;
    golfWindDirection: string;
    golfWind: string;
    golfWeather: string;
    golfTemperature: number;
    showGolfWeather: boolean;
    showGolfPersonalAchievement: boolean;
    showGolfCurrentRank: boolean;
    showGolfScoreList: boolean;
    currentRound: number;
    defaultLogo: boolean;
    rounds: number[];
    matches: number[];
    easyButtons: number[];
    currentMatch: number;
    players: Player[];
    players8: Player8[];
    currentPlayer: string;
    golfScore?: number;
    currentHole: number;
    currentPar: number;
    yds: number;
    forBall: string;
    currentStrokes: number;
    playerName: string;
    roundName: string;
    position1?: number;
    position2?: number;
    position3?: number;
    position4?: number;
    position5?: number;
    position6?: number;
    name1: string;
    name2: string;
    name3: string;
    name4: string;
    name5: string;
    name6: string;
    golfScore1?: number;
    golfScore2?: number;
    golfScore3?: number;
    golfScore4?: number;
    golfScore5?: number;
    golfScore6?: number;
    thru1?: number;
    thru2?: number;
    thru3?: number;
    thru4?: number;
    thru5?: number;
    thru6?: number;
    today1?: number;
    today2?: number;
    today3?: number;
    today4?: number;
    today5?: number;
    today6?: number;
    hole1: number;
    hole2: number;
    hole3: number;
    hole4: number;
    hole5: number;
    hole6: number;
    hole7: number;
    hole8: number;
    hole9: number;
    holeTotal9: number;
    hole10: number;
    hole11: number;
    hole12: number;
    hole13: number;
    hole14: number;
    hole15: number;
    hole16: number;
    hole17: number;
    hole18: number;
    holeTotal18: number;
    previewGolfWeather: boolean;
    previewGolfPersonalAchievement: boolean;
    previewGolfCurrentRank: boolean;
    previewGolfScoreList: boolean;
    previewGolfCaption: boolean;
    previewGolfIndividualCaption: boolean;
    previewGolfLogo: boolean;
    showGolfCurrentPlayer: boolean;
}

interface XmlUrlFormValues {
    xmlUrl: string;
}

const { Option } = Select;
const DEFAULT_PAR = [4, 5, 3, 4, 3, 4, 4, 5, 4, 4, 3, 4, 4, 5, 4, 3, 5, 4];
const DEFAULT_HOLES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
const DEFAULT_YDS = {
    '1': [387, 506, 183, 312, 173, 391, 397, 529, 404, 297, 148, 363, 406, 520, 286, 148, 580, 440],
    '2':  [295, 384, 167, 226, 158, 328, 337, 441, 313, 253, 125, 304, 327, 418, 207, 115, 434, 326]
}
const DEFAULTLOGO = 'https://cbt-upload.obs.cn-north-4.myhuaweicloud.com/2bc15c84-8eea-4f62-aa49-56fc2ab32359.png';

const SOCKET_PATH = '/captionserver/socket.io';
let socket: Socket;

export class GolfController extends React.Component<golfProps, golfState> {
    private time1Ref : React.RefObject<HTMLSpanElement> = React.createRef();
    private time2Ref : React.RefObject<HTMLSpanElement> = React.createRef();
    private golfLogoUrlFormRef = React.createRef<FormInstance>();
    private captionFormRef = React.createRef<FormInstance>();
    private weatherFormRef = React.createRef<FormInstance>();
    private currentTime: number;
    private readonly xmlUrlFormRef: RefObject<FormInstance> = React.createRef();
    public constructor(props: golfProps) {
        super(props);
        this.state = {
            xmlUrl: 'https://lianmeng.scoringchina.com/data/api.xmlmatch/xml?matchid=82',
            showXmlUrl: false,
            saving: false,
            totalScore: [],
            showGolfIndividualCaption: false,
            showGolfLogo: false,
            golfLogoUrl: '',
            golfCaptionTitle: '2022 君品习酒·联盟杯',
            golfCaption1: '中国商学院校友高尔夫联盟系列赛',
            golfTransparency: 0,
            showGolfCaption: false,
            golfWindDirection: '',
            golfWind: '',
            golfWeather: '',
            golfTemperature: 30,
            showGolfWeather: false,
            showGolfPersonalAchievement: false,
            showGolfCurrentRank: false,
            showGolfScoreList: false,
            currentRound: 0,
            defaultLogo: false,
            rounds: [],
            matches: [],
            easyButtons: [],
            currentMatch: 0,
            players: [],
            players8: [],
            currentPlayer: '',
            golfScore: 0,
            currentHole: 0,
            currentPar: 1,
            yds: 0,
            forBall: '1st shot',
            currentStrokes: 1,
            playerName: '',
            roundName: '',
            position1: 1,
            position2: 2,
            position3: 3,
            position4: 4,
            position5: 5,
            position6: 0,
            name1: '',
            name2: '',
            name3: '',
            name4: '',
            name5: '',
            name6: '',
            golfScore1: 0,
            golfScore2: 0,
            golfScore3: 0,
            golfScore4: 0,
            golfScore5: 0,
            golfScore6: 0,
            thru1: 0,
            thru2: 0,
            thru3: 0,
            thru4: 0,
            thru5: 0,
            thru6: 0,
            today1: 0,
            today2: 0,
            today3: 0,
            today4: 0,
            today5: 0,
            today6: 0,
            hole1: 0,
            hole2: 0,
            hole3: 0,
            hole4: 0,
            hole5: 0,
            hole6: 0,
            hole7: 0,
            hole8: 0,
            hole9: 0,
            holeTotal9: 0,
            hole10: 0,
            hole11: 0,
            hole12: 0,
            hole13: 0,
            hole14: 0,
            hole15: 0,
            hole16: 0,
            hole17: 0,
            hole18: 0,
            holeTotal18: 0,
            previewGolfWeather: false,
            previewGolfPersonalAchievement: false,
            previewGolfCurrentRank: false,
            previewGolfScoreList: false,
            previewGolfCaption: false,
            previewGolfIndividualCaption: false,
            previewGolfLogo: false,
            showGolfCurrentPlayer: true,
        }
    }

    public async componentDidMount(): Promise<void> {
        document.title = '云导播-高级图文';
        this.currentTime = window.setInterval(() => {
            let date = new Date();
            notNull(this.time1Ref.current).innerHTML = date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日';
            notNull(this.time2Ref.current).innerHTML = date.getHours() + ':' + (date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes()) + ':' + (date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds());
        },1000);
        await this.init();
        this.joinRoom();
        socket.on("refresh", (data) => {
            switch (data.type) {
                case 'golfRefreshData':
                    this.golfRefresh(data);
                    socket.removeListener('refresh');
                    break;
            }
        });
        setTimeout(()=> {
            socket.emit("controller", {
                type: "listenGolf",
            });
        },1000)
    }

    public componentWillUnmount() {
        clearInterval(this.currentTime);
    }

    private joinRoom(): void {
        socket = io(CAPTION_BASE_URL, {
            path: SOCKET_PATH,
            transports: ['websocket'],
            query: {
                roomId: this.props.match.params.id,
                token: sessionStorage.getItem('token') ? `Bearer ${sessionStorage.getItem('token')}` : null,
            }
        });
        this.unauthorized();
    }

    private golfRefresh(refreshData: GolfRefreshData): void {
        this.setState({
            showGolfIndividualCaption: refreshData.showGolfIndividualCaption,
            showGolfCaption: refreshData.showGolfCaption,
            showGolfScoreList: refreshData.showGolfScoreList,
            showGolfPersonalAchievement: refreshData.showGolfPersonalAchievement,
            showGolfCurrentPlayer: refreshData.showGolfCurrentPlayer,
            previewGolfIndividualCaption: refreshData.showGolfIndividualCaption,
            previewGolfCaption: refreshData.showGolfCaption,
            previewGolfScoreList: refreshData.showGolfScoreList,
            previewGolfPersonalAchievement: refreshData.showGolfPersonalAchievement,
            golfTransparency: refreshData.golfTransparency,
            golfCaptionTitle: refreshData.golfCaptionTitle,
            golfCaption1: refreshData.golfCaption1,
            players8: refreshData.players8,
            golfWindDirection: refreshData.golfWindDirection,
            golfWind: refreshData.golfWind,
            golfWeather: refreshData.golfWeather,
            golfTemperature: refreshData.golfTemperature,
            showGolfWeather: refreshData.showGolfWeather,
            showGolfLogo: refreshData.showGolfLogo,
            previewGolfWeather: refreshData.showGolfWeather,
            previewGolfLogo: refreshData.showGolfLogo,
            golfLogoUrl: refreshData.golfLogoUrl,
            playerName: refreshData.playerName,
            currentHole: refreshData.currentHole,
            currentRound: refreshData.currentRound,
            roundName: refreshData.roundName,
            golfScore: refreshData.golfScore,
            currentPar: refreshData.currentPar,
            currentStrokes: refreshData.currentStrokes,
            yds: refreshData.yds,
            forBall: refreshData.forBall,
            showGolfCurrentRank: refreshData.showGolfCurrentRank,
            previewGolfCurrentRank: refreshData.showGolfCurrentRank,
            position1: refreshData.position1,
            position2: refreshData.position2,
            position3: refreshData.position3,
            position4: refreshData.position4,
            position5: refreshData.position5,
            position6: refreshData.position6,
            name1: refreshData.name1,
            name2: refreshData.name2,
            name3: refreshData.name3,
            name4: refreshData.name4,
            name5: refreshData.name5,
            name6: refreshData.name6,
            golfScore1: refreshData.golfScore1,
            golfScore2: refreshData.golfScore2,
            golfScore3: refreshData.golfScore3,
            golfScore4: refreshData.golfScore4,
            golfScore5: refreshData.golfScore5,
            golfScore6: refreshData.golfScore6,
            thru1: refreshData.thru1,
            thru2: refreshData.thru2,
            thru3: refreshData.thru3,
            thru4: refreshData.thru4,
            thru5: refreshData.thru5,
            thru6: refreshData.thru6,
            today1: refreshData.today1,
            today2: refreshData.today2,
            today3: refreshData.today3,
            today4: refreshData.today4,
            today5: refreshData.today5,
            today6: refreshData.today6,
            hole1: refreshData.hole1,
            hole2: refreshData.hole2,
            hole3: refreshData.hole3,
            hole4: refreshData.hole4,
            hole5: refreshData.hole5,
            hole6: refreshData.hole6,
            hole7: refreshData.hole7,
            hole8: refreshData.hole8,
            hole9: refreshData.hole9,
            holeTotal9: refreshData.holeTotal9,
            hole10: refreshData.hole10,
            hole11: refreshData.hole11,
            hole12: refreshData.hole12,
            hole13: refreshData.hole13,
            hole14: refreshData.hole14,
            hole15: refreshData.hole15,
            hole16: refreshData.hole16,
            hole17: refreshData.hole17,
            hole18: refreshData.hole18,
            holeTotal18: refreshData.holeTotal18
        });
        this.golfLogoUrlFormRef.current?.setFieldsValue({
            logoUrl: refreshData.golfLogoUrl
        });
        this.weatherFormRef.current?.setFieldsValue({
            weather: refreshData.golfWeather,
            wind: refreshData.golfWind,
            windDirection: refreshData.golfWindDirection
        });
        this.captionFormRef.current?.setFieldsValue({
            golfCaptionTitle: refreshData.golfCaptionTitle,
            golfCaption1: refreshData.golfCaption1
        })
    }

    private unauthorized(): void {
        socket.on('unauthorized', () => {
            history.push('/advancecg');
        })
    }

    private readonly columns = [
        {
            key: 'position',
            title: '排名',
            render: (text: unknown, player: Player): JSX.Element => <span>{ player.position }</span>
        },
        {
            key: 'name',
            title: '姓名',
            render: (text: unknown, player: Player): JSX.Element => <span>{ player.name }</span>
        },
        {
            key: 'score',
            title: '总分',
            render: (text: unknown, player: Player): JSX.Element => <span>{ player.score }</span>
        },
        {
            key: 'thru',
            title: '完成洞',
            render: (text: unknown, player: Player): JSX.Element => <span>{ player.rounds[this.state.currentRound].thru }</span>
        },
        {
            key: 'today',
            title: '今日得分',
            render: (text: unknown, player: Player): JSX.Element => <span>{ player.rounds[this.state.currentRound].today }</span>
        },
    ]

    public render(): JSX.Element {
        return (
            <div className='golfHome'>
                <div className='golfTop'>
                    <div className='topContent'>
                        <i title={'返回上一级'} className={'back fas fa-arrow-left'} onClick={() => goBack()}/>
                        <img className='logo' alt={'logo'} src={logo}/>
                        <button className='clean' onClick={() => this.cleanAdvanceCG()}>一键清屏</button>
                        <Typography.Paragraph
                            className='copy'
                            copyable={{
                                text: `${CAPTION_SHOW_URL}/captionShow?id=${this.props.match.params.id}`,
                                icon: [<button className='iconButton'>复制输出地址</button>],
                                tooltips: ['复制', '复制成功']}}/>
                        <button className='xmlUrl' onClick={() => this.showXmlUrlModal()}>输入xml地址</button>
                        {
                            this.state.showXmlUrl &&
                            <Modal
                                visible={this.state.showXmlUrl}
                                closable={false}
                                okText={'确认'}
                                cancelText={'取消'}
                                onOk={() => this.xmlUrlFormRef.current?.submit()}
                                onCancel={() => this.cancelInputXmlUrl()}
                                confirmLoading={this.state.saving}
                            >
                                <Form
                                    ref={this.xmlUrlFormRef}
                                    layout="vertical"
                                    onFinish={(values: XmlUrlFormValues) => this.createOrUpdateXmlUrl(values)}
                                    initialValues={{
                                        xmlUrl: this.state.xmlUrl,
                                    }}
                                >
                                    <Form.Item
                                        name="xmlUrl"
                                        label={'xml地址'}
                                        rules={[{ required: true, message: 'xmlUrl不能为空' },
                                                { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' }
                                        ]}>
                                        <Input placeholder={'请输入xml地址'} />
                                    </Form.Item>
                                </Form>
                            </Modal>
                        }
                    </div>
                </div>
                <div className='golfContentDiv'>
                    <div className='previewDiv'>
                        <div className='leftController'>
                            <div className='golfCompetitor'>
                                <div className='golfCompetitorDiv'>
                                    <p>参赛名单</p>
                                    <p style={{ width: '60px'}}>分组</p>
                                    <Select  style={{ width: '90px', height: '29px', background: '#17242D', color: '#FFFFFF', display: 'flex', float: 'left', marginTop: '1px'}}
                                             bordered={false}
                                             value={this.state.currentMatch}
                                             defaultValue={this.state.currentMatch}
                                             onChange={(value: number) => this.handleMatchChange(value)}>
                                        <Option key={0} value={0}>所有</Option>
                                        {
                                            this.state.matches.map((c, index) =>
                                                <Option key={index + 1} value={c}>{c}</Option>
                                            )
                                        }
                                    </Select>
                                    <button onClick={() => this.groupByMatch()}>分组排名</button>
                                    <button onClick={() => this.refresh()}>刷新数据</button>
                                </div>
                                <div className='golfCompetitorTableDiv'>
                                    <Table
                                        dataSource={ this.state.players.filter(player =>
                                            Number(player.rounds[this.state.currentRound].match) === this.state.currentMatch ||
                                            this.state.currentMatch === 0
                                        )}
                                        rowKey={player => player.id }
                                        pagination={{defaultPageSize: 8, showSizeChanger: false}}
                                        scroll={{y: '200px'}}
                                        size={'small'}
                                    >
                                        <Table.Column title='排名' key='position' dataIndex='position'/>
                                        <Table.Column title='姓名' key='name' dataIndex='name'/>
                                        <Table.Column title='得分' key='score' dataIndex='score'/>
                                        <Table.Column title='操作' key='action' render={
                                            (text: unknown, player:Player) =>
                                                <Space size='small'>
                                                    <button
                                                        key={player.id}
                                                        style={{background: `${this.state.currentPlayer === player.id ? '#05A905' : 'white'}`, borderRadius: '6px', height: '22px', fontSize: '12px'}}
                                                        onClick={() => {this.handlePlayerChange(player.id)}}
                                                    >
                                                        选择
                                                    </button>
                                                </Space>
                                        } />
                                    </Table>
                                </div>
                                <div style={{display: "flex"}}>
                                    <Select
                                        mode={"multiple"}
                                        style={{ width: '200px', background: '#17242D', marginLeft: '10px'}}
                                        size={"small"}
                                        value={this.state.easyButtons}
                                        bordered={false}
                                        allowClear
                                        placeholder="选择快捷分组"
                                        onChange={(value => this.setEasyButtons(value))}
                                    >
                                        {
                                            this.state.matches.map((c, index) =>
                                                <Option key={index} value={c}>{c}</Option>
                                            )
                                        }
                                    </Select>
                                    <div className={'shortcutButton'} style={{width: '50%'}}>
                                        {
                                            this.state.easyButtons.map((c, index) =>
                                                <button
                                                    key={index}
                                                    style={{background: '#05A905', borderRadius: '6px', marginLeft: '10px', width: '40px'}}
                                                    onClick={() => this.handleMatchChange(c)}>
                                                    {c}
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <Form
                                ref={this.golfLogoUrlFormRef}
                                className='golfLogoUrlDiv'
                            >
                                <div className='golfLogoUrl'>
                                    <p>角标</p>
                                </div>
                                <div className='golfLogoUrlController'>
                                    <div>
                                        <div style={{marginTop: '5px', width: '85%'}}>
                                            <p style={{display: 'flex', float: 'left', margin: 'auto 20px'}}>图片地址：</p>
                                            <Form.Item
                                                name='logoUrl'
                                                validateStatus="warning"
                                                help={'建议格式：*.png;*.jpeg;*.jpg;*.bmp'}
                                            >
                                                <Input
                                                    style={{background: '#17242D', color: '#FFFFFF'}}
                                                    value={this.state.golfLogoUrl}
                                                    placeholder={'请输入图片链接地址'}
                                                    bordered={false}
                                                    onChange={(e) => {this.setState({golfLogoUrl: e.target.value})}}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div>
                                            <p style={{display: 'flex', float: 'left', margin: '20px'}}>图片预览：</p>
                                            <Image
                                                style={{marginTop: '20px', marginLeft: '20px'}}
                                                width={80}
                                                src={this.state.golfLogoUrl}
                                                fallback={logo}
                                                preview={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'flex-end'}}>
                                    <div style={{width: '100px', display: 'flex', float: 'left', alignItems: 'center', lineHeight: '28px', marginTop: '0px'}}>
                                        <div style={{marginLeft: '5px', width: '100px'}}>默认LOGO</div>
                                        <Switch
                                            size={"small"}
                                            defaultChecked={false}
                                            onChange={(checked => {this.setState({defaultLogo: checked})})}
                                        />
                                    </div>
                                    <button onClick={() => this.previewGolfLogo()}
                                            style={{background: `${this.state.previewGolfLogo ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '115px'}}>
                                        {this.state.previewGolfLogo ? '隐藏' : '预览'}
                                    </button>
                                    <button onClick={() => this.showGolfLogo()}
                                            style={{background: `${this.state.showGolfLogo ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '20px'}}>
                                        {this.state.showGolfLogo ? '隐藏' : '输出'}
                                    </button>
                                    <button onClick={() => this.setGolfLogo()}
                                            style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                                        刷新
                                    </button>
                                </div>
                            </Form>
                        </div>
                        <div className='previewContentDiv'>
                            <div className='previewTop'>
                                <div className='topDiv'>
                                    <span>画面预览</span>
                                    <span ref={this.time1Ref}/>
                                    <span ref={this.time2Ref}/>
                                </div>
                            </div>
                            <div className='preview'>
                                <Preview
                                    roomId={this.props.match.params.id}
                                />
                            </div>
                        </div>
                        <div className='rightController'>
                            <Form
                                ref={this.weatherFormRef}
                                className='golfWeather'>
                                <div className='golfWeatherDiv'>
                                    <p>天气</p>
                                </div>
                                <div className='scoreCardController'>
                                    <div className='aWeather'>
                                        <div style={{display: 'flex', float: 'left'}}>
                                            <p style={{margin: '5px 10px auto 24px'}}>天气：</p>
                                            <Form.Item
                                                name="weather"
                                                style={{width: '50%'}}
                                                rules={[
                                                    // { required: true, message: '不能为空' },
                                                    // { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                                    { max: 10, message: '天气不超过10个字' }]}
                                            >
                                                <Input
                                                    value={this.state.golfWeather}
                                                    maxLength={10}
                                                    bordered={false}
                                                    onChange={(e) => this.setState({ golfWeather: e.target.value })}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div style={{display: 'flex', float: 'left'}}>
                                            <p style={{margin: '5px 10px auto 24px'}}>风力：</p>
                                            <Form.Item
                                                name="wind"
                                                style={{width: '50%'}}
                                                rules={[
                                                    // { required: true, message: '不能为空' },
                                                    // { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                                    { max: 10, message: '风力不超过10个字' }]}
                                            >
                                                <Input
                                                    value={this.state.golfWind}
                                                    maxLength={10}
                                                    bordered={false}
                                                    onChange={(e) => this.setState({ golfWind: e.target.value })}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='aWeather'>
                                        <div style={{display: 'flex', float: 'left'}}>
                                            <p style={{margin: 'auto 10px auto 24px'}}>温度：</p>
                                            <InputNumber
                                                min={-50}
                                                max={50}
                                                precision={0}
                                                bordered={false}
                                                value={this.state.golfTemperature}
                                                style={{width: '50%'}}
                                                onChange={(value: number) => this.setState({ golfTemperature: value === null ? 0 : value })}
                                            />
                                        </div>
                                        <div style={{display: 'flex', float: 'left', marginTop: '27px'}}>
                                            <p style={{margin: '5px 10px auto 24px'}}>风向：</p>
                                            <Form.Item
                                                name="windDirection"
                                                style={{width: '50%'}}
                                                rules={[
                                                    // { required: true, message: '不能为空' },
                                                    // { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                                    { max: 10, message: '风向不超过10个字' }]}
                                            >
                                                <Input
                                                    value={this.state.golfWindDirection}
                                                    maxLength={10}
                                                    bordered={false}
                                                    onChange={(e) => this.setState({ golfWindDirection: e.target.value })}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'flex-end', marginTop: '15px'}}>
                                    <button onClick={() => this.previewGolfWeather()}
                                            style={{background: `${this.state.previewGolfWeather ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                                        {this.state.previewGolfWeather ? '隐藏' : '预览'}
                                    </button>
                                    <button onClick={() => this.showGolfWeather()}
                                            style={{background: `${this.state.showGolfWeather ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '20px'}}>
                                        {this.state.showGolfWeather ? '隐藏' : '输出'}
                                    </button>
                                    <button onClick={() => this.setGolfWeather()}
                                            style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                                        刷新
                                    </button>
                                </div>
                            </Form>
                            <div className='golfTotalRank'>
                                <div className='golfTotalRankDiv'>
                                    <p>总排名</p>
                                    <p>回合</p>
                                    <Select  style={{ width: '90px', height: '29px', background: '#17242D', color: '#FFFFFF', display: 'flex', float: 'left', marginTop: '1px'}}
                                             bordered={false}
                                             defaultValue={this.state.currentRound}
                                             onChange={(value: number) => this.handleRoundChange(value)}>
                                        {
                                            this.state.rounds.map((c, index) =>
                                                <Option key={index} value={index}>{c}</Option>
                                            )
                                        }
                                    </Select>
                                    <p>回合名称</p>
                                    <Input
                                        bordered={false}
                                        maxLength={7}
                                        style={{width: '90px', height: '29px', marginTop: '1px'}}
                                        value={this.state.roundName}
                                        onChange={(e) => {this.setState({roundName: e.target.value})}}
                                    />
                                </div>
                                <div className='golfTotalRankTableDiv'>
                                    <Table
                                        scroll={{scrollToFirstRowOnChange: true, y: '200px'}}
                                        pagination={{defaultPageSize: 8, showSizeChanger: false}}
                                        size={"small"}
                                        dataSource={ this.state.players}
                                        rowKey={ player => player.id}
                                        columns={ this.columns }
                                        onChange={(pageConfig) => {
                                            this.players8(pageConfig.current);
                                        }}
                                    />
                                </div>
                                <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'flex-end', marginTop: '20px'}}>
                                    <button onClick={() => this.previewGolfScoreList()}
                                            style={{background: `${this.state.previewGolfScoreList ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                                        {this.state.previewGolfScoreList ? '隐藏' : '预览'}
                                    </button>
                                    <button onClick={() => this.showGolfScoreList()}
                                            style={{background: `${this.state.showGolfScoreList ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '20px'}}>
                                        {this.state.showGolfScoreList ? '隐藏' : '输出'}
                                    </button>
                                    <button onClick={() => this.setGolfScoreList()} style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                                        刷新
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='golfController'>
                        <div className='golfIndividualCaption'>
                            <div className='golfIndividualScoreCard'>
                                <p>单洞成绩</p>
                            </div>
                            <div style={{width: '100%', display: 'flex', float: 'left', fontSize: '0.1566rem', alignItems: 'center', lineHeight: '0.2rem', marginTop: '20px'}}>
                                <div style={{marginLeft: '45px', width: '50px'}}>姓名</div>
                                <Input
                                    bordered={false}
                                    maxLength={9}
                                    style={{width: '90px'}}
                                    value={this.state.playerName}
                                    onChange={(e) => {this.setState({playerName: e.target.value})}}
                                />
                            </div>
                            <div style={{width: '100%', display: 'flex', float: 'left', fontSize: '0.1566rem', alignItems: 'center', lineHeight: '0.2rem', marginTop: '10px'}}>
                                <div style={{marginLeft: '45px', width: '50px'}}>洞序号</div>
                                <Select  style={{ width: '90px', height: '32px', background: '#17242D', color: '#FFFFFF'}}
                                         bordered={false}
                                         value={this.state.currentHole}
                                         onChange={(value: number) => this.handleHoleChange(value)}>
                                    {
                                        DEFAULT_HOLES.map((c, index) =>
                                            <Option key={index} value={c-1}>{c}</Option>)
                                    }
                                </Select>
                                <div style={{marginLeft: '25px', width: '50px'}}>总分</div>
                                <InputNumber
                                    bordered={false}
                                    max={999}
                                    min={-999}
                                    value={this.state.golfScore}
                                    onChange={(value: number) => {this.setState({golfScore: value === null ? 0 : value})}}
                                />
                            </div>
                            <div style={{display: 'flex', float: 'left', fontSize: '0.1566rem', alignItems: 'center', lineHeight: '0.2rem', marginTop: '10px'}}>
                                <div style={{marginLeft: '45px', width: '50px'}}>标准杆</div>
                                <InputNumber
                                    bordered={false}
                                    min={0}
                                    max={99}
                                    value={this.state.currentPar}
                                    onChange={(value: number) => {this.setState({currentPar: value === null ? 1 : value}, () => {
                                        this.setState({
                                            forBall: this.forBall(this.state.currentPar, this.state.currentStrokes),
                                        })
                                    })}}
                                />
                                <div style={{marginLeft: '25px', width: '50px'}}>当前杆</div>
                                <InputNumber
                                    bordered={false}
                                    min={0}
                                    max={99}
                                    value={this.state.currentStrokes}
                                    onChange={(value: number) => {this.setState({currentStrokes: value === null ? 1 : value}, () => {
                                        this.setState({
                                            forBall: this.forBall(this.state.currentPar, this.state.currentStrokes),
                                        })
                                    })}}
                                />
                            </div>
                            <div style={{width: '100%', display: 'flex', float: 'left', fontSize: '0.1566rem', alignItems: 'center', lineHeight: '0.2rem', marginTop: '10px'}}>
                                <div style={{marginLeft: '45px', width: '50px'}}>距离</div>
                                <InputNumber
                                    bordered={false}
                                    min={0}
                                    value={this.state.yds}
                                    onChange={(value: number) => {this.setState({yds: value === null ? 0 : value})}}
                                />
                                <div style={{marginLeft: '25px', width: '50px'}}>
                                    For Ball
                                </div>
                                <Input
                                    bordered={false}
                                    style={{width: '90px'}}
                                    value={this.state.forBall}
                                    onChange={(e) => {this.setState({forBall: e.target.value})}}
                                />
                            </div>
                            <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'flex-end', marginTop: '35px'}}>
                                <button onClick={() => this.previewGolfIndividualCaption()}
                                        style={{background: `${this.state.previewGolfIndividualCaption ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                                    {this.state.previewGolfIndividualCaption ? '隐藏' : '预览'}
                                </button>
                                <button onClick={() => this.showGolfIndividualCaption()}
                                        style={{background: `${this.state.showGolfIndividualCaption ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '20px'}}>
                                    {this.state.showGolfIndividualCaption ? '隐藏' : '输出'}
                                </button>
                                <button onClick={() => this.setGolfIndividualCaption()}
                                        style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                                    刷新
                                </button>
                            </div>
                        </div>
                        <div className='golfCurrentRank'>
                            <div className='golfCurrentRankDiv'>
                                <p>实时排名</p>
                            </div>
                            <div style={{display: 'flex', float: 'left', fontSize: '0.1566rem', alignItems: 'center', lineHeight: '0.2rem', marginTop: '10px'}}>
                                <div style={{marginLeft: '25px', width: '50px', textAlign: 'center'}}>前五名</div>
                                <div style={{marginLeft: '10px', width: '50px', textAlign: 'center'}}>名次</div>
                                <div style={{marginLeft: '20px', width: '50px', textAlign: 'center'}}>姓名</div>
                                <div style={{marginLeft: '20px', width: '50px', textAlign: 'center'}}>总分</div>
                                <div style={{marginLeft: '7px', width: '50px', textAlign: 'center'}}>今日得分</div>
                            </div>
                            <div style={{display: 'flex', float: 'left', fontSize: '0.1333rem', alignItems: 'center', lineHeight: '0.18rem', marginTop: '10px'}}>
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    min={0}
                                    max={999}
                                    style={{height: '22px', width: '50px', marginLeft: '93px'}}
                                    value={this.state.position1}
                                    onChange={(value: number) => {this.setState({position1: value})}}
                                />
                                <Input
                                    bordered={false}
                                    maxLength={6}
                                    style={{height: '22px', width: '80px', marginLeft: '5px'}}
                                    value={this.state.name1}
                                    onChange={(e) => {this.setState({name1: e.target.value})}}
                                />
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    max={999}
                                    min={-999}
                                    style={{height: '22px', width: '50px', marginLeft: '5px'}}
                                    value={this.state.golfScore1}
                                    onChange={(value: number) => {this.setState({golfScore1: value})}}
                                />
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    max={999}
                                    min={-999}
                                    style={{height: '22px', width: '50px', marginLeft: '5px'}}
                                    value={this.state.today1}
                                    onChange={(value: number) => {this.setState({today1: value})}}
                                />
                            </div>
                            <div style={{display: 'flex', float: 'left', fontSize: '0.1333rem', alignItems: 'center', lineHeight: '0.18rem', marginTop: '5px'}}>
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    min={0}
                                    max={999}
                                    style={{height: '22px', width: '50px', marginLeft: '93px'}}
                                    value={this.state.position2}
                                    onChange={(value: number) => {this.setState({position2: value})}}
                                />
                                <Input
                                    bordered={false}
                                    maxLength={6}
                                    style={{height: '22px', width: '80px', marginLeft: '5px'}}
                                    value={this.state.name2}
                                    onChange={(e) => {this.setState({name2: e.target.value})}}
                                />
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    max={999}
                                    min={-999}
                                    style={{height: '22px', width: '50px', marginLeft: '5px'}}
                                    value={this.state.golfScore2}
                                    onChange={(value: number) => {this.setState({golfScore2: value})}}
                                />
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    max={999}
                                    min={-999}
                                    style={{height: '22px', width: '50px', marginLeft: '5px'}}
                                    value={this.state.today2}
                                    onChange={(value: number) => {this.setState({today2: value})}}
                                />
                            </div>
                            <div style={{display: 'flex', float: 'left', fontSize: '0.1333rem', alignItems: 'center', lineHeight: '0.18rem', marginTop: '5px'}}>
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    min={0}
                                    max={999}
                                    style={{height: '22px', width: '50px', marginLeft: '93px'}}
                                    value={this.state.position3}
                                    onChange={(value: number) => {this.setState({position3: value})}}
                                />
                                <Input
                                    bordered={false}
                                    maxLength={6}
                                    style={{height: '22px', width: '80px', marginLeft: '5px'}}
                                    value={this.state.name3}
                                    onChange={(e) => {this.setState({name3: e.target.value})}}
                                />
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    max={999}
                                    min={-999}
                                    style={{height: '22px', width: '50px', marginLeft: '5px'}}
                                    value={this.state.golfScore3}
                                    onChange={(value: number) => {this.setState({golfScore3: value})}}
                                />
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    max={999}
                                    min={-999}
                                    style={{height: '22px', width: '50px', marginLeft: '5px'}}
                                    value={this.state.today3}
                                    onChange={(value: number) => {this.setState({today3: value})}}
                                />
                            </div>
                            <div style={{display: 'flex', float: 'left', fontSize: '0.1333rem', alignItems: 'center', lineHeight: '0.18rem', marginTop: '5px'}}>
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    min={0}
                                    max={999}
                                    style={{height: '22px', width: '50px', marginLeft: '93px'}}
                                    value={this.state.position4}
                                    onChange={(value: number) => {this.setState({position4: value})}}
                                />
                                <Input
                                    bordered={false}
                                    maxLength={6}
                                    style={{height: '22px', width: '80px', marginLeft: '5px'}}
                                    value={this.state.name4}
                                    onChange={(e) => {this.setState({name4: e.target.value})}}
                                />
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    max={999}
                                    min={-999}
                                    style={{height: '22px', width: '50px', marginLeft: '5px'}}
                                    value={this.state.golfScore4}
                                    onChange={(value: number) => {this.setState({golfScore4: value})}}
                                />
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    max={999}
                                    min={-999}
                                    style={{height: '22px', width: '50px', marginLeft: '5px'}}
                                    value={this.state.today4}
                                    onChange={(value: number) => {this.setState({today4: value})}}
                                />
                            </div>
                            <div style={{display: 'flex', float: 'left', fontSize: '0.1333rem', alignItems: 'center', lineHeight: '0.18rem', marginTop: '5px'}}>
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    min={0}
                                    max={999}
                                    style={{height: '22px', width: '50px', marginLeft: '93px'}}
                                    value={this.state.position5}
                                    onChange={(value: number) => {this.setState({position5: value})}}
                                />
                                <Input
                                    bordered={false}
                                    maxLength={6}
                                    style={{height: '22px', width: '80px', marginLeft: '5px'}}
                                    value={this.state.name5}
                                    onChange={(e) => {this.setState({name5: e.target.value})}}
                                />
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    max={999}
                                    min={-999}
                                    style={{height: '22px', width: '50px', marginLeft: '5px'}}
                                    value={this.state.golfScore5}
                                    onChange={(value: number) => {this.setState({golfScore5: value})}}
                                />
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    max={999}
                                    min={-999}
                                    style={{height: '22px', width: '50px', marginLeft: '5px'}}
                                    value={this.state.today5}
                                    onChange={(value: number) => {this.setState({today5: value})}}
                                />
                            </div>
                            <div style={{display: 'flex', float: 'left', fontSize: '0.1333rem', alignItems: 'center', lineHeight: '0.18rem', marginTop: '5px'}}>
                                <div style={{marginLeft: '25px', width: '50px', fontSize: '0.1566rem'}}>个人排名</div>
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    min={0}
                                    max={999}
                                    style={{height: '22px', width: '50px', marginLeft: '18px'}}
                                    value={this.state.position6}
                                    onChange={(value: number) => {this.setState({position6: value})}}
                                />
                                <Input
                                    bordered={false}
                                    maxLength={6}
                                    style={{height: '22px', width: '80px', marginLeft: '5px'}}
                                    value={this.state.name6}
                                    onChange={(e) => {this.setState({name6: e.target.value})}}
                                />
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    max={999}
                                    min={-999}
                                    style={{height: '22px', width: '50px', marginLeft: '5px'}}
                                    value={this.state.golfScore6}
                                    onChange={(value: number) => {this.setState({golfScore6: value})}}
                                />
                                <InputNumber
                                    size={"small"}
                                    bordered={false}
                                    max={999}
                                    min={-999}
                                    style={{height: '22px', width: '50px', marginLeft: '5px'}}
                                    value={this.state.today6}
                                    onChange={(value: number) => {this.setState({today6: value})}}
                                />
                            </div>
                            <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'flex-end', marginTop: '20px'}}>
                                <div style={{width: '150px', display: 'flex', float: 'left', fontSize: '0.1566rem', alignItems: 'center', lineHeight: '0.1rem', marginTop: '0px'}}>
                                    <div style={{marginLeft: '5px', width: '100px'}}>是否显示个人排名</div>
                                    <Switch
                                        size={"small"}
                                        checked={this.state.showGolfCurrentPlayer}
                                        onChange={(checked => {this.setState({showGolfCurrentPlayer: checked})})}
                                    />
                                </div>
                                <button onClick={() => this.previewGolfCurrentRank()}
                                        style={{background: `${this.state.previewGolfCurrentRank ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '33px'}}>
                                    {this.state.previewGolfCurrentRank ? '隐藏' : '预览'}
                                </button>
                                <button onClick={() => this.showGolfCurrentRank()}
                                        style={{background: `${this.state.showGolfCurrentRank ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '20px'}}>
                                    {this.state.showGolfCurrentRank ? '隐藏' : '输出'}
                                </button>
                                <button onClick={() => this.setGolfCurrentRank()}
                                        style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                                    刷新
                                </button>
                            </div>
                        </div>
                        <div className='golfPersonalAchievement'>
                            <div className='golfPersonalAchievementDiv'>
                                <p>个人总成绩</p>
                            </div>
                            <div className='golfPersonalAchievementContent'>
                                <div className='golfPersonalPar1Div'>
                                    <div className='golfPersonalPar1'>
                                        <div className='spanPar1'><span>标准杆</span></div>
                                        {
                                            DEFAULT_PAR.slice(0, 9).map((c, index) =>
                                                <div key={index} style={{width: '55px', textAlign: 'center'}}><span>{c}</span></div>
                                            )
                                        }
                                        <div style={{width: '50px', textAlign: 'center'}}><span>{this.parTotal(DEFAULT_PAR, 0, 9)}</span></div>
                                    </div>
                                </div>
                                <div className='golfPersonalStrokes1Div'>
                                    <div className='golfPersonalStrokes1'>
                                        <div className='spanStrokes1'>成绩</div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole1}
                                                onChange={(value: number) => {this.setState({hole1: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole2}
                                                onChange={(value: number) => {this.setState({hole2: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole3}
                                                onChange={(value: number) => {this.setState({hole3: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole4}
                                                onChange={(value: number) => {this.setState({hole4: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole5}
                                                onChange={(value: number) => {this.setState({hole5: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole6}
                                                onChange={(value: number) => {this.setState({hole6: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole7}
                                                onChange={(value: number) => {this.setState({hole7: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole8}
                                                onChange={(value: number) => {this.setState({hole8: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole9}
                                                onChange={(value: number) => {this.setState({hole9: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={999}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.holeTotal9}
                                                onChange={(value: number) => {this.setState({holeTotal9: value === null ? 0 : value})}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='golfPersonalPar2Div'>
                                    <div className='golfPersonalPar2'>
                                        <div className='spanPar2'>标准杆</div>
                                        {
                                            DEFAULT_PAR.slice(9, 18).map((c, index) =>
                                                <div key={index} style={{width: '55px', textAlign: 'center'}}><span>{c}</span></div>
                                            )
                                        }
                                        <div style={{width: '50px', textAlign: 'center'}}><span>{this.parTotal(DEFAULT_PAR, 9, 18)}</span></div>
                                    </div>
                                </div>
                                <div className='golfPersonalStrokes2Div'>
                                    <div className='golfPersonalStrokes2'>
                                        <div className='spanStrokes2'>成绩</div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole10}
                                                onChange={(value: number) => {this.setState({hole10: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole11}
                                                onChange={(value: number) => {this.setState({hole11: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole12}
                                                onChange={(value: number) => {this.setState({hole12: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole13}
                                                onChange={(value: number) => {this.setState({hole13: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole14}
                                                onChange={(value: number) => {this.setState({hole14: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole15}
                                                onChange={(value: number) => {this.setState({hole15: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole16}
                                                onChange={(value: number) => {this.setState({hole16: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole17}
                                                onChange={(value: number) => {this.setState({hole17: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={99}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.hole18}
                                                onChange={(value: number) => {this.setState({hole18: value === null ? 0 : value})}}
                                            />
                                        </div>
                                        <div style={{width: '60px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                min={0}
                                                max={999}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.holeTotal18}
                                                onChange={(value: number) => {this.setState({holeTotal18: value === null ? 0 : value})}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='golfPersonalTotalDiv'>
                                    <div className='golfPersonalTotal'>
                                        <div className='totalDiv'>总分</div>
                                        <div style={{width: '60px', height: '32px' , margin: 'auto'}}>
                                            <InputNumber
                                                bordered={false}
                                                max={999}
                                                min={-999}
                                                style={{width: '50px', height: '32px'}}
                                                value={this.state.golfScore}
                                                onChange={(value: number) => {this.setState({golfScore: value === null ? 0 : value})}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'flex-end', marginTop: '8px'}}>
                                <button onClick={() => this.previewGolfPersonalAchievement()}
                                        style={{background: `${this.state.previewGolfPersonalAchievement ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                                    {this.state.previewGolfPersonalAchievement ? '隐藏' : '预览'}
                                </button>
                                <button onClick={() => this.showGolfPersonalAchievement()}
                                        style={{background: `${this.state.showGolfPersonalAchievement ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '20px'}}>
                                    {this.state.showGolfPersonalAchievement ? '隐藏' : '输出'}
                                </button>
                                <button onClick={() => this.setGolfPersonalAchievement()}
                                        style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                                    刷新
                                </button>
                            </div>
                        </div>
                        <Form
                            ref={this.captionFormRef}
                            initialValues={{
                                golfCaptionTitle: this.state.golfCaptionTitle,
                                golfCaption1: this.state.golfCaption1
                            }}
                            className='golfCaptionDiv'>
                            <div className='golfCaption'>
                                <p>字幕条</p>
                            </div>
                            <div className='golfCaptionController'>
                                <div style={{width: '100%', height: '100%'}}>
                                    <div style={{paddingTop: '50px'}}>
                                        <p style={{margin: '3px 33px auto 20px', display: 'flex', float: 'left'}}>标题：</p>
                                        <Form.Item
                                            name="golfCaptionTitle"
                                            rules={[{ required: true, message: '确认为空？', warningOnly: true },
                                                // { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                                { max: 16, message: '标题不超过16个字' }]}
                                        >
                                            <Input
                                                placeholder={'不超过16个字'}
                                                value={this.state.golfCaptionTitle}
                                                style={{width: '90%', background: '#17242D', color: '#FFFFFF'}}
                                                bordered={false}
                                                maxLength={16}
                                                onChange={(e) => this.setState({ golfCaptionTitle: e.target.value })}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{paddingTop: '20px'}}>
                                        <p style={{margin: '3px 25px auto 20px', display: 'flex', float: 'left'}}>文字1：</p>
                                        <Form.Item
                                            name="golfCaption1"
                                            rules={[{ required: true, message: '确认为空？', warningOnly: true },
                                                // { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                                { max: 33, message: '文字1不超过33个字' }]}
                                        >
                                            <Input
                                                placeholder={'不超过33个字'}
                                                value={this.state.golfCaption1}
                                                style={{width: '90%', background: '#17242D', color: '#FFFFFF'}}
                                                bordered={false}
                                                maxLength={33}
                                                onChange={(e) => this.setState({ golfCaption1: e.target.value })}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'flex-end', marginTop: '33px'}}>
                                <button onClick={() => this.previewGolfCaption()}
                                        style={{background: `${this.state.previewGolfCaption ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                                    {this.state.previewGolfCaption ? '隐藏' : '预览'}
                                </button>
                                <button onClick={() => this.showGolfCaption()}
                                        style={{background: `${this.state.showGolfCaption ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '20px'}}>
                                    {this.state.showGolfCaption ? '隐藏' : '输出'}
                                </button>
                                <button onClick={() => this.setGolfCaption()}
                                        style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                                    刷新
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }

    private showXmlUrlModal(): void {
        this.setState({
            showXmlUrl: true,
        })
    }

    private cancelInputXmlUrl(): void {
        this.setState({
            showXmlUrl: false,
        })
    }

    private async createOrUpdateXmlUrl(values: XmlUrlFormValues): Promise<void> {
        this.setState({
            saving: true,
            xmlUrl: values.xmlUrl,
        }, async () => {
            this.setState({
                saving: false,
                showXmlUrl: false
            })
            await this.init();
        })
    }

    private cleanAdvanceCG(): void {
        this.setState({
            showGolfWeather: false,
            showGolfScoreList: false,
            showGolfPersonalAchievement: false,
            showGolfCaption: false,
            showGolfLogo: false,
            showGolfIndividualCaption: false,
            showGolfCurrentRank: false,
            previewGolfWeather: false,
            previewGolfScoreList: false,
            previewGolfPersonalAchievement: false,
            previewGolfCaption: false,
            previewGolfLogo: false,
            previewGolfIndividualCaption: false,
            previewGolfCurrentRank: false,
        })
        golfService.cleanAdvanceCG(socket);
    }

    private initMatches(): void {
        const matchesLength = Math.max(...(this.state.players.map(c =>
            Number(c.rounds[this.state.currentRound].match)
        )))
        const matches: number[] = [];
        for (let i = 1; i <= matchesLength; i++) {
            matches.push(i);
        }
        this.setState({
            matches: matches,
        })
    }

    private async init(): Promise<void> {
        try {
            const players = await golfService.getPlayers(this.state.xmlUrl);
            this.setState({
                players: players || [],
            }, () => {
                this.players8(1);
                const rounds: number[] = [];
                for (let i = 1; i <= this.state.players[0].rounds.length; i++) {
                    rounds.push(i);
                }
                this.setState({
                    rounds: rounds
                })
                this.initMatches();
            });
            message.success('获取xml数据成功');
        }catch (e) {
            message.error('获取xml数据失败');
        }
    }

    private async refresh(): Promise<void> {
        await this.init().then(() => {
            this.handlePlayerChange(this.state.currentPlayer);
        });
    }

    private groupByMatch(): void {
        if (this.state.currentMatch !== 0) {
            const matchPlayers = this.state.players.filter(player =>
                Number(player.rounds[this.state.currentRound].match) === this.state.currentMatch
            );
            this.setState({
                position1: matchPlayers[0]?.position,
                position2: matchPlayers[1]?.position,
                position3: matchPlayers[2]?.position,
                position4: matchPlayers[3]?.position,
                position5: undefined,
                position6: undefined,
                name1: matchPlayers[0]?.name,
                name2: matchPlayers[1]?.name,
                name3: matchPlayers[2]?.name,
                name4: matchPlayers[3]?.name,
                name5: '',
                name6: '',
                golfScore1: matchPlayers[0]?.score,
                golfScore2: matchPlayers[1]?.score,
                golfScore3: matchPlayers[2]?.score,
                golfScore4: matchPlayers[3]?.score,
                golfScore5: undefined,
                golfScore6: undefined,
                thru1: matchPlayers[0]?.rounds[this.state.currentRound].thru,
                thru2: matchPlayers[1]?.rounds[this.state.currentRound].thru,
                thru3: matchPlayers[2]?.rounds[this.state.currentRound].thru,
                thru4: matchPlayers[3]?.rounds[this.state.currentRound].thru,
                thru5: undefined,
                thru6: undefined,
                today1: matchPlayers[0]?.rounds[this.state.currentRound].today,
                today2: matchPlayers[1]?.rounds[this.state.currentRound].today,
                today3: matchPlayers[2]?.rounds[this.state.currentRound].today,
                today4: matchPlayers[3]?.rounds[this.state.currentRound].today,
                today5: undefined,
                today6: undefined,
            })
        }
    }

    private players8(page?: number): void {
        const playerCount = this.state.players.length;
        if (page) {
            const players =
                Math.ceil(playerCount / 8) > page
                    ? this.state.players.slice(8 * (page - 1), 8 * page)
                    : this.state.players.slice(8 * (page - 1));
            this.setState({
                players8: players.map(c => ({
                    position: c.position,
                    name: c.name,
                    score: c.score,
                    thru: c.rounds[this.state.currentRound].thru,
                    today: c.rounds[this.state.currentRound].today
                } as Player8))
            })
        }
    }

    private parTotal(defaultPar: number[], start: number, end: number): number {
        let parTotal = 0;
        defaultPar.slice(start, end).forEach(value => {
            parTotal += value;
        })
        return parTotal;
    }

    private async handleRoundChange(value: number): Promise<void> {
        this.setState({
            currentRound: value,
            easyButtons: [],
            currentMatch: 0,
        }, async () => {
            try {
                const players = await golfService.getPlayers(this.state.xmlUrl);
                this.setState({
                    players: players || [],
                }, () => {
                    this.players8(1);
                    this.initMatches();
                });
            }catch (e) {
                message.error('获取xml数据失败');
            }
        })
    }

    private handleMatchChange(value: number): void {
        this.setState({
            currentMatch: value
        }, async () => {
            try {
                const players = await golfService.getPlayers(this.state.xmlUrl);
                this.setState({
                    players: players || [],
                })
            }catch (e) {
                message.error('获取xml数据失败');
            }
        })
    }

    private handleHoleChange(value: number): void {
        const player = this.state.players.find(player => player.id === this.state.currentPlayer );
        if (player) {
            this.setState({
                currentHole: value
            }, () => {
                this.setState({
                    currentPar: DEFAULT_PAR[this.state.currentHole],
                    currentStrokes: player.rounds[this.state.currentRound].scores[this.state.currentHole].strokes,
                    yds: (player.id === '1119' || player.id === '102' || player.id === '181' || player.id === '2651') ? DEFAULT_YDS['2'][this.state.currentHole] : DEFAULT_YDS['1'][this.state.currentHole],
                    forBall: this.forBall(DEFAULT_PAR[this.state.currentHole], player.rounds[this.state.currentRound].scores[this.state.currentHole].strokes)
                })
            })
        }
    }

    private handlePlayerChange(playerId: string): void {
        const player = this.state.players.find(player => player.id === playerId );
        if (player) {
            this.setState({
                currentPlayer: playerId,
                golfScore: player.score,
                currentHole: this.currentHole(player),
                playerName: player.name,
            }, () => {
                this.setState({
                    currentPar: DEFAULT_PAR[this.state.currentHole],
                    currentStrokes: player.rounds[this.state.currentRound].scores[this.state.currentHole].strokes,
                    yds: (player.id === '1119' || player.id === '102' || player.id === '181' || player.id === '2651') ? DEFAULT_YDS['2'][this.state.currentHole] : DEFAULT_YDS['1'][this.state.currentHole],
                    forBall: this.forBall(DEFAULT_PAR[this.state.currentHole], player.rounds[this.state.currentRound].scores[this.state.currentHole].strokes),
                    position1: this.state.players[0]?.position,
                    position2: this.state.players[1]?.position,
                    position3: this.state.players[2]?.position,
                    position4: this.state.players[3]?.position,
                    position5: this.state.players[4]?.position,
                    position6: player.position,
                    name1: this.state.players[0]?.name,
                    name2: this.state.players[1]?.name,
                    name3: this.state.players[2]?.name,
                    name4: this.state.players[3]?.name,
                    name5: this.state.players[4]?.name,
                    name6: player.name,
                    golfScore1: this.state.players[0]?.score,
                    golfScore2: this.state.players[1]?.score,
                    golfScore3: this.state.players[2]?.score,
                    golfScore4: this.state.players[3]?.score,
                    golfScore5: this.state.players[4]?.score,
                    golfScore6: player.score,
                    thru1: this.state.players[0]?.rounds[this.state.currentRound].thru,
                    thru2: this.state.players[1]?.rounds[this.state.currentRound].thru,
                    thru3: this.state.players[2]?.rounds[this.state.currentRound].thru,
                    thru4: this.state.players[3]?.rounds[this.state.currentRound].thru,
                    thru5: this.state.players[4]?.rounds[this.state.currentRound].thru,
                    thru6: player.rounds[this.state.currentRound]?.thru,
                    today1: this.state.players[0]?.rounds[this.state.currentRound].today,
                    today2: this.state.players[1]?.rounds[this.state.currentRound].today,
                    today3: this.state.players[2]?.rounds[this.state.currentRound].today,
                    today4: this.state.players[3]?.rounds[this.state.currentRound].today,
                    today5: this.state.players[4]?.rounds[this.state.currentRound].today,
                    today6: player.rounds[this.state.currentRound]?.today,
                    hole1: player.rounds[this.state.currentRound].scores[0].strokes,
                    hole2: player.rounds[this.state.currentRound].scores[1].strokes,
                    hole3: player.rounds[this.state.currentRound].scores[2].strokes,
                    hole4: player.rounds[this.state.currentRound].scores[3].strokes,
                    hole5: player.rounds[this.state.currentRound].scores[4].strokes,
                    hole6: player.rounds[this.state.currentRound].scores[5].strokes,
                    hole7: player.rounds[this.state.currentRound].scores[6].strokes,
                    hole8: player.rounds[this.state.currentRound].scores[7].strokes,
                    hole9: player.rounds[this.state.currentRound].scores[8].strokes,
                    holeTotal9: this.holeTotal(player.rounds[this.state.currentRound].scores, 0, 9),
                    hole10: player.rounds[this.state.currentRound].scores[9].strokes,
                    hole11: player.rounds[this.state.currentRound].scores[10].strokes,
                    hole12: player.rounds[this.state.currentRound].scores[11].strokes,
                    hole13: player.rounds[this.state.currentRound].scores[12].strokes,
                    hole14: player.rounds[this.state.currentRound].scores[13].strokes,
                    hole15: player.rounds[this.state.currentRound].scores[14].strokes,
                    hole16: player.rounds[this.state.currentRound].scores[15].strokes,
                    hole17: player.rounds[this.state.currentRound].scores[16].strokes,
                    hole18: player.rounds[this.state.currentRound].scores[17].strokes,
                    holeTotal18: this.holeTotal(player.rounds[this.state.currentRound].scores, 9, 18),
                })
            })
        }
    }

    private holeTotal(playerRound: Score[], start: number, end: number): number {
        let holeTotal = 0;
        playerRound.slice(start, end).forEach(value => {
            holeTotal += value.strokes;
        })
        return holeTotal;
    }

    private currentHole(player: Player): number {
        const currentHole = player.rounds[this.state.currentRound].thru + player.rounds[this.state.currentRound].startingTee
        return currentHole > 18 ? currentHole - 19 : currentHole - 1;
    }

    private forBall(currentPar: number, currentStrokes: number): string {
        if (currentStrokes === 0) {
            return '';
        }
        if (currentPar < currentStrokes) {
            return (currentStrokes - currentPar) === 1 ? 'Bogey' : (currentStrokes - currentPar) === 2 ? 'Double Bogey' : '';
        } else if (currentPar === currentStrokes) {
            return 'For Par';
        } else {
            const difference = currentPar - currentStrokes;
            return difference === 1 ? 'Birdie'
                : difference === 2 ? 'Eagle'
                    : currentStrokes === 1 ? '1st shot'
                        : currentStrokes === 2 ? '2nd shot'
                            : currentStrokes === 3 ? '3rd shot'
                                : currentStrokes === 4 ? '4th shot'
                                    : currentStrokes === 5 ? '5th shot'
                                        : currentStrokes === 6 ? '6th shot'
                                            : currentStrokes === 7 ? '7th shot'
                                                : currentStrokes === 8 ? '8th shot'
                                                    : currentStrokes === 9 ? '9th shot'
                                                    : '';
        }
    }

    private showGolfScoreList(): void {
        this.setState({
            showGolfScoreList: !this.state.showGolfScoreList
        }, () => {
            golfService.showGolfScoreList(socket, this.state.showGolfScoreList, this.state.players8, this.state.golfCaptionTitle, this.state.golfCaption1, this.state.currentRound, this.state.roundName);
        })
    }

    private setGolfScoreList(): void {
        golfService.setGolfScoreList(socket, this.state.players8, this.state.golfCaptionTitle, this.state.golfCaption1, this.state.currentRound, this.state.roundName);
    }

    private previewGolfScoreList(): void {
        this.setState({
            previewGolfScoreList: !this.state.previewGolfScoreList
        }, () => {
            golfService.previewGolfScoreList(socket, this.state.previewGolfScoreList, this.state.players8, this.state.golfCaptionTitle, this.state.golfCaption1, this.state.currentRound, this.state.roundName);
        })
    }

    private showGolfPersonalAchievement(): void {
        this.setState({
            showGolfPersonalAchievement: !this.state.showGolfPersonalAchievement
        }, () => {
            golfService.showGolfPersonalAchievement(socket, this.state);
        })
    }

    private previewGolfPersonalAchievement(): void {
        this.setState({
            previewGolfPersonalAchievement: !this.state.previewGolfPersonalAchievement
        }, () => {
            golfService.previewGolfPersonalAchievement(socket, this.state);
        })
    }

    private setGolfPersonalAchievement(): void {
        golfService.setGolfPersonalAchievement(socket, this.state);
    }

    private showGolfCurrentRank(): void {
        this.setState({
            showGolfCurrentRank: !this.state.showGolfCurrentRank
        }, () => {
            golfService.showGolfCurrentRank(socket, this.state);
        })
    }

    private previewGolfCurrentRank(): void {
        this.setState({
            previewGolfCurrentRank: !this.state.previewGolfCurrentRank
        }, () => {
            golfService.previewGolfCurrentRank(socket, this.state);
        })
    }

    private setGolfCurrentRank(): void {
        golfService.setGolfCurrentRank(socket, this.state)
    }

    private showGolfIndividualCaption(): void {
        this.setState({
            showGolfIndividualCaption: !this.state.showGolfIndividualCaption
        }, () =>{
            golfService.showGolfIndividualCaption(socket, this.state.showGolfIndividualCaption, this.state.playerName, this.state.currentHole + 1, this.state.golfScore, this.state.currentPar, this.state.currentStrokes, this.state.yds, this.state.forBall);
        })
    }

    private previewGolfIndividualCaption(): void {
        this.setState({
            previewGolfIndividualCaption: !this.state.previewGolfIndividualCaption
        }, () =>{
            golfService.previewGolfIndividualCaption(socket, this.state.previewGolfIndividualCaption, this.state.playerName, this.state.currentHole + 1, this.state.golfScore, this.state.currentPar, this.state.currentStrokes, this.state.yds, this.state.forBall);
        })
    }

    private setGolfIndividualCaption(): void {
        golfService.setGolfIndividualCaption(socket, this.state.playerName, this.state.currentHole + 1, this.state.golfScore, this.state.currentPar, this.state.currentStrokes, this.state.yds, this.state.forBall);
    }

    private showGolfLogo(): void {
        this.setState({
            showGolfLogo: !this.state.showGolfLogo
        }, () => {
            golfService.showGolfLogo(socket, this.state.showGolfLogo, this.state.defaultLogo ?  DEFAULTLOGO : this.state.golfLogoUrl);
        })
    }

    private previewGolfLogo(): void {
        this.setState({
            previewGolfLogo: !this.state.previewGolfLogo
        }, () => {
            golfService.previewGolfLogo(socket, this.state.previewGolfLogo, this.state.defaultLogo ?  DEFAULTLOGO : this.state.golfLogoUrl);
        })
    }

    private setGolfLogo(): void {
        golfService.setGolfLogo(socket, this.state.defaultLogo ?  DEFAULTLOGO : this.state.golfLogoUrl);
    }

    private showGolfCaption(): void {
        this.setState({
            showGolfCaption: !this.state.showGolfCaption,
        }, () => {
            golfService.showGolfCaption(socket, this.state.showGolfCaption, this.state.golfCaptionTitle, this.state.golfCaption1, this.state.golfTransparency);
        })
    }

    private previewGolfCaption(): void {
        this.setState({
            previewGolfCaption: !this.state.previewGolfCaption,
        }, () => {
            golfService.previewGolfCaption(socket, this.state.previewGolfCaption, this.state.golfCaptionTitle, this.state.golfCaption1, this.state.golfTransparency);
        })
    }

    private setGolfCaption(): void {
        golfService.setGolfCaption(socket, this.state.golfCaptionTitle, this.state.golfCaption1, this.state.golfTransparency);
    }

    private showGolfWeather(): void {
        this.setState({
            showGolfWeather: !this.state.showGolfWeather
        }, () => {
            golfService.showGolfWeather(socket, this.state.showGolfWeather, this.state.golfWeather, this.state.golfTemperature, this.state.golfWind, this.state.golfWindDirection);
        })
    }

    private previewGolfWeather(): void {
        this.setState({
            previewGolfWeather: !this.state.previewGolfWeather
        }, () => {
            golfService.previewGolfWeather(socket, this.state.previewGolfWeather, this.state.golfWeather, this.state.golfTemperature, this.state.golfWind, this.state.golfWindDirection);
        })
    }

    private setGolfWeather(): void {
        golfService.setGolfWeather(socket, this.state.golfWeather, this.state.golfTemperature, this.state.golfWind, this.state.golfWindDirection);
    }

    private setEasyButtons(value: number[]): void {
        if (value.length <= 8) {
            this.setState({easyButtons: value})
        }else {
            message.warning('最多设置8个快捷分组按钮');
        }
    }
}
