import React from "react";
import logo from "../preview/img/logo.png";
import './Basketball.scss';
import {Form, Image, Input, Typography} from "antd";
import { CAPTION_BASE_URL, CAPTION_SHOW_URL } from "../../common/constants";
import { RouteComponentProps } from "react-router-dom";
import { io, Socket } from 'socket.io-client';
import { BasketballPreview } from "./BasketballPreview";
import { BasketballRefreshData, TeamMembers } from "../../common/types";
import { goBack, notNull } from "../../common/utils";
import { basketballService } from "../../services/BasketballService";
import { history } from "../../common/history";
import { FormInstance } from "antd/es/form";
import { BasketballScoreCard } from "./elements/basketballScoreCard/BasketballScoreCard";
import { BasketballCompetitor } from "./elements/basketballCompetitor/BasketballCompetitor";
import { BasketballCommentary } from "./elements/basketballCommentary/BasketballCommentary";
import { BasketballLogo } from "./elements/basketballLogo/basketballLogo";
import { BasketballTotalScore } from "./elements/basketballTotalScore/basketballTotalScore";
import { BasketballCaption } from "./elements/basketballCaption/basketballCaption";

interface basketballProps extends RouteComponentProps<{id: string}> {
}

interface basketballStates {
    basketballLogoUrl: string;
    commentator: string;
    guest: string;
    venue: string;
    score1: number;
    score2: number;
    name1: string;
    name2: string;
    currentTeam: number;
    title: string;
    text: string;
    showCaption: boolean;
    competition: string;
    word: string;
    previewBasketballCaption: boolean;
    showBasketballCaption: boolean;
    team1Logo: string;
    team2Logo: string;
    showCommentary: boolean;
    previewCommentary: boolean;
    previewBasketballScoreCard: boolean;
    showBasketballScoreCard: boolean;
    previewBasketballLogo: boolean;
    showBasketballLogo: boolean;
    team1Members: TeamMembers;
    team2Members: TeamMembers;
    previewBasketballCompetitor: boolean;
    showBasketballCompetitor: boolean;
    previewBasketballTotalScore: boolean;
    showBasketballTotalScore: boolean;
}

const SOCKET_PATH = '/captionserver/socket.io';
let socket: Socket;

export class Basketball extends React.Component<basketballProps, basketballStates> {
    private time1Ref : React.RefObject<HTMLSpanElement> = React.createRef();
    private time2Ref : React.RefObject<HTMLSpanElement> = React.createRef();
    private basketballScoreCardFormRef = React.createRef<FormInstance>();
    private basketballCompetitorFormRef = React.createRef<FormInstance>();
    private basketballCommentaryFormRef = React.createRef<FormInstance>();
    private basketballTotalScoreFormRef = React.createRef<FormInstance>();
    private basketballLogoUrlFormRef = React.createRef<FormInstance>();
    private basketballCaptionFormRef = React.createRef<FormInstance>();
    private basketballTeam1logoFormRef = React.createRef<FormInstance>();
    private basketballTeam2logoFormRef = React.createRef<FormInstance>();
    private currentTime: number;

    public constructor(props: basketballProps) {
        super(props);
        this.state = {
            basketballLogoUrl: '',
            commentator: '',
            guest: '',
            venue: '',
            score1: 0,
            score2: 0,
            name1: '主队',
            name2: '客队',
            currentTeam: 0,
            title: '',
            text: '',
            showCaption: false,
            competition: '',
            word: '',
            previewBasketballCaption: false,
            showBasketballCaption: false,
            team1Logo: '',
            team2Logo: '',
            showCommentary: false,
            previewCommentary: false,
            previewBasketballScoreCard: false,
            showBasketballScoreCard: false,
            previewBasketballLogo: false,
            showBasketballLogo: false,
            team1Members: {},
            team2Members: {},
            previewBasketballCompetitor: false,
            showBasketballCompetitor: false,
            previewBasketballTotalScore: false,
            showBasketballTotalScore: false,
        }
    }

    public async componentDidMount(): Promise<void> {
        document.title = '云导播-高级图文';
        this.currentTime = window.setInterval(() => {
            let date = new Date();
            notNull(this.time1Ref.current).innerHTML = date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日';
            notNull(this.time2Ref.current).innerHTML = date.getHours() + ':' + (date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes()) + ':' + (date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds());
        },1000);
        this.basketballScoreCardFormRef.current?.setFieldsValue({
            name1: this.state.name1,
            name2: this.state.name2,
        })
        this.joinRoom();
        this.listener();
    }

    public componentWillUnmount(): void {
        clearInterval(this.currentTime);
    }

    private joinRoom(): void {
        socket = io(CAPTION_BASE_URL, {
            path: SOCKET_PATH,
            transports: ['websocket'],
            query: {
                roomId: this.props.match.params.id,
                token: sessionStorage.getItem('token') ? `Bearer ${sessionStorage.getItem('token')}` : null,
            }
        });
        this.unauthorized();
    }
    private listener(): void {
        socket.on("refresh", (data) => {
            switch (data.type) {
                case 'basketballRefreshData':
                    this.basketballRefresh(data);
                    socket.removeListener('refresh');
                    break;
            }
        });
        setTimeout(()=> {
            basketballService.listen(socket);
        },1000)
    }

    private unauthorized(): void {
        socket.on('unauthorized', () => {
            history.push('/advancecg');
        })
    }

    private basketballRefresh(refresh: BasketballRefreshData): void {
        this.setState({
            commentator: refresh.commentator,
            guest: refresh.guest,
            venue: refresh.venue,
            showCommentary: refresh.showCommentary,
            previewCommentary: refresh.showCommentary,
            team1Logo: refresh.team1Logo,
            team2Logo: refresh.team2Logo,
            name1: refresh.name1,
            name2: refresh.name2,
            score1: refresh.score1,
            score2: refresh.score2,
            showBasketballScoreCard: refresh.showBasketballScoreCard,
            previewBasketballScoreCard: refresh.showBasketballScoreCard,
            basketballLogoUrl: refresh.basketballLogoUrl,
            competition: refresh.competition,
            word: refresh.word,
            showBasketballLogo: refresh.showBasketballLogo,
            showBasketballCaption: refresh.showBasketballCaption,
            previewBasketballLogo: refresh.showBasketballLogo,
            previewBasketballCaption: refresh.showBasketballCaption,
            title: refresh.title,
            text: refresh.text,
            showBasketballCompetitor: refresh.showBasketballCompetitor,
            previewBasketballCompetitor: refresh.showBasketballCompetitor,
            team1Members: refresh.team1Members,
            team2Members: refresh.team2Members,
            currentTeam: refresh.currentTeam,
            showBasketballTotalScore: refresh.showBasketballTotalScore,
            previewBasketballTotalScore: refresh.showBasketballTotalScore
        });
        this.basketballScoreCardFormRef.current?.setFieldsValue({
            name1: this.state.name1,
            name2: this.state.name2,
        });
        this.basketballCompetitorFormRef.current?.setFieldsValue({
            logoUrl: refresh.currentTeam === 0 ? refresh.team1Logo : refresh.team2Logo
        });
        this.basketballCommentaryFormRef.current?.setFieldsValue({
            commentator: refresh.commentator,
            guest: refresh.guest,
            venue: refresh.venue
        });
        this.basketballTotalScoreFormRef.current?.setFieldsValue({
            competition: refresh.competition,
            word: refresh.word
        });
        this.basketballLogoUrlFormRef.current?.setFieldsValue({
            competition: refresh.competition,
            logoUrl: refresh.basketballLogoUrl
        });
        this.basketballCaptionFormRef.current?.setFieldsValue({
            captionTitle: refresh.title,
            captionText: refresh.text
        })
    }

    public render(): JSX.Element {
        return (
            <div className='basketball'>
                <div className='basketballTop'>
                    <div className='topContent'>
                        <i title={'返回上一级'} className={'back fas fa-arrow-left'} onClick={() => goBack()}/>
                        <img className='logo' alt={'logo'} src={logo}/>
                        <Typography.Paragraph
                            className='copy'
                            copyable={{
                                text: `${CAPTION_SHOW_URL}/basketballShow?id=${this.props.match.params.id}`,
                                icon: [<button className='iconButton'>复制输出地址</button>],
                                tooltips: ['复制', '复制成功']}}/>
                    </div>
                </div>
                <div className='basketballContentDiv'>
                    <div className='previewDiv'>
                        <div className='leftController'>
                            <BasketballScoreCard
                                formRef= {this.basketballScoreCardFormRef}
                                name1= {this.state.name1}
                                name2= {this.state.name2}
                                score1= {this.state.score1}
                                score2= {this.state.score2}
                                previewBasketballScoreCard= {this.state.previewBasketballScoreCard}
                                showBasketballScoreCard= {this.state.showBasketballScoreCard}
                                addScore1= {increment => this.addScore1(increment)}
                                addScore2= {increment => this.addScore2(increment)}
                                show= {() => this.showBasketballScoreCard()}
                                preview= {() => this.previewBasketballScoreCard()}
                                set= {() => this.setBasketballScoreCard()}
                                handChangeScore1= {score => this.handChangeScore1(score)}
                                handChangeScore2= {score => this.handChangeScore2(score)}
                                handChangeName1= {name => this.handChangeName1(name)}
                                handChangeName2= {name => this.handChangeName2(name)}
                            />
                            <BasketballCompetitor
                                formRef= {this.basketballCompetitorFormRef}
                                name1= {this.state.name1}
                                name2= {this.state.name2}
                                team1Logo= {this.state.team1Logo}
                                team2Logo= {this.state.team2Logo}
                                currentTeam= {this.state.currentTeam}
                                team1Members= {this.state.team1Members}
                                team2Members= {this.state.team2Members}
                                previewBasketballCompetitor= {this.state.previewBasketballCompetitor}
                                showBasketballCompetitor= {this.state.showBasketballCompetitor}
                                changeTeam= {value => this.changeTeam(value)}
                                handTeamLogo1Change= {logo => this.handTeamLogo1Change(logo)}
                                handTeamLogo2Change= {logo => this.handTeamLogo2Change(logo)}
                                handCurrentTeamMembersChange= {currentTeamMembers => this.handCurrentTeamMembersChange(currentTeamMembers)}
                                show= {() => this.showBasketballCompetitor()}
                                preview= {() => this.previewBasketballCompetitor()}
                                set= {() => this.setBasketballCompetitor()}
                            />
                        </div>
                        <div className='previewContentDiv'>
                            <div className='previewTop'>
                                <div className='topDiv'>
                                    <span>画面预览</span>
                                    <span ref={this.time1Ref}/>
                                    <span ref={this.time2Ref}/>
                                </div>
                            </div>
                            <div className='preview'>
                                <BasketballPreview
                                    roomId={this.props.match.params.id}
                                />
                            </div>
                        </div>
                        <div className='rightController'>
                            <BasketballCommentary
                                formRef= {this.basketballCommentaryFormRef}
                                commentator= {this.state.commentator}
                                guest= {this.state.guest}
                                venue= {this.state.venue}
                                previewCommentary= {this.state.previewCommentary}
                                showCommentary= {this.state.showCommentary}
                                handCommentatorChange= {commentator => this.handCommentatorChange(commentator)}
                                handGuestChange= {guest => this.handGuestChange(guest)}
                                handVenueChange= {venue => this.handVenueChange(venue)}
                                show= {() => this.showCommentary()}
                                preview= {() => this.previewCommentary()}
                                set= {() => this.setCommentary()}
                            />
                            <BasketballTotalScore
                                formRef= {this.basketballTotalScoreFormRef}
                                competition= {this.state.competition}
                                word= {this.state.word}
                                score1= {this.state.score1}
                                score2= {this.state.score2}
                                previewBasketballTotalScore= {this.state.previewBasketballTotalScore}
                                showBasketballTotalScore= {this.state.showBasketballTotalScore}
                                handChangeScore1= {score => this.handChangeScore1(score)}
                                handChangeScore2= {score => this.handChangeScore2(score)}
                                handWordChange= {word => this.handWordChange(word)}
                                handCompetitionChange= {competition => this.handTotalCompetitionChange(competition)}
                                show= {() => this.showBasketballTotalScore()}
                                preview= {() => this.previewBasketballTotalScore()}
                                set= {() => this.setBasketballTotalScore()}
                            />
                        </div>
                    </div>
                    <div className='bottomController'>
                        <BasketballLogo
                            formRef= {this.basketballLogoUrlFormRef}
                            competition= {this.state.competition}
                            basketballLogoUrl= {this.state.basketballLogoUrl}
                            previewBasketballLogo= {this.state.previewBasketballLogo}
                            showBasketballLogo= {this.state.showBasketballLogo}
                            handCompetitionChange= {competition => this.handLogoCompetitionChange(competition)}
                            handBasketballLogoUrlChange= {url => this.handBasketballLogoUrlChange(url)}
                            show= {() => this.showBasketballLogo()}
                            preview= {() => this.previewBasketballLogo()}
                            set= {() => this.setBasketballLogo()}
                        />
                        <Form
                            ref={this.basketballTeam1logoFormRef}
                            className='team1logoDiv'>
                            <div className='team1logo'>
                                <p>主队logo</p>
                            </div>
                            <div className='team1logoController'>
                                <div style={{width: '100%', height: '100%'}}>
                                    <div style={{width: '85%'}}>
                                        <p style={{display: 'flex', float: 'left', margin: 'auto 20px'}}>球队logo：</p>
                                        <Form.Item
                                            name='logoUrl'
                                            validateStatus="warning"
                                            help={'建议格式：*.png;*.jpeg;*.jpg;*.bmp'}
                                        >
                                            <Input
                                                style={{background: '#17242D', color: '#FFFFFF'}}
                                                value={this.state.team1Logo}
                                                placeholder={'请输入图片链接地址'}
                                                bordered={false}
                                                onChange={(e) => {
                                                    this.setState({
                                                        team1Logo: e.target.value
                                                    });
                                                    if (this.state.currentTeam === 0) {
                                                        this.basketballCompetitorFormRef.current?.setFieldsValue({
                                                            logoUrl: e.target.value
                                                        })
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{marginTop: '58px'}}>
                                        <p style={{display: 'flex', float: 'left', margin: 'auto 20px'}}>logo预览：</p>
                                        <Image
                                            style={{marginTop: '5px', marginLeft: '20px'}}
                                            width={80}
                                            src={this.state.team1Logo}
                                            preview={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                        <Form
                            ref={this.basketballTeam2logoFormRef}
                            className='team2logoDiv'>
                            <div className='team2logo'>
                                <p>客队logo</p>
                            </div>
                            <div className='team2logoController'>
                                <div style={{width: '100%', height: '100%'}}>
                                    <div style={{width: '85%'}}>
                                        <p style={{display: 'flex', float: 'left', margin: 'auto 20px'}}>球队logo：</p>
                                        <Form.Item
                                            name='logoUrl'
                                            validateStatus="warning"
                                            help={'建议格式：*.png;*.jpeg;*.jpg;*.bmp'}
                                        >
                                            <Input
                                                style={{background: '#17242D', color: '#FFFFFF'}}
                                                value={this.state.team2Logo}
                                                placeholder={'请输入图片链接地址'}
                                                bordered={false}
                                                onChange={(e) => {
                                                    this.setState({
                                                        team2Logo: e.target.value
                                                    });
                                                    if (this.state.currentTeam === 1) {
                                                        this.basketballCompetitorFormRef.current?.setFieldsValue({
                                                            logoUrl: e.target.value
                                                        })
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{marginTop: '58px'}}>
                                        <p style={{display: 'flex', float: 'left', margin: 'auto 20px'}}>logo预览：</p>
                                        <Image
                                            style={{marginTop: '5px', marginLeft: '20px'}}
                                            width={80}
                                            src={this.state.team2Logo}
                                            preview={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                        <BasketballCaption
                            formRef= {this.basketballCaptionFormRef}
                            title= {this.state.title}
                            text= {this.state.text}
                            previewBasketballCaption= {this.state.previewBasketballCaption}
                            showBasketballCaption= {this.state.showBasketballCaption}
                            handTitleChange= {title => this.handTitleChange(title)}
                            handTextChange= {text => this.handTextChange(text)}
                            show= {() => this.showBasketballCaption()}
                            preview= {() => this.previewBasketballCaption()}
                            set= {() => this.setBasketballCaption()}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private addScore1(increment: number): void {
        this.setState({
            score1: this.state.score1 + increment > 999 ? 999 : this.state.score1 + increment
        })
    }

    private addScore2(increment: number): void {
        this.setState({
            score2: this.state.score2 + increment > 999 ? 999 : this.state.score2 + increment
        })
    }

    private previewBasketballTotalScore(): void {
        this.setState({
            previewBasketballTotalScore: !this.state.previewBasketballTotalScore
        }, () => {
            basketballService.previewBasketballTotalScore(
                socket,
                this.state.competition,
                this.state.word,
                this.state.name1,
                this.state.name2,
                this.state.score1,
                this.state.score2,
                this.state.team1Logo,
                this.state.team2Logo,
                this.state.previewBasketballTotalScore);
        })
    }

    private showBasketballTotalScore(): void {
        this.setState({
            showBasketballTotalScore: !this.state.showBasketballTotalScore
        }, () => {
            basketballService.showBasketballTotalScore(socket,
                this.state.competition,
                this.state.word,
                this.state.name1,
                this.state.name2,
                this.state.score1,
                this.state.score2,
                this.state.team1Logo,
                this.state.team2Logo,
                this.state.showBasketballTotalScore);
        })
    }

    private setBasketballTotalScore(): void {
        basketballService.setBasketballTotalScore(socket,
            this.state.competition,
            this.state.word,
            this.state.name1,
            this.state.name2,
            this.state.score1,
            this.state.score2,
            this.state.team1Logo,
            this.state.team2Logo,);
    }

    private handWordChange(word: string): void {
        this.setState({
            word
        })
    }

    private previewBasketballCompetitor(): void {
        this.setState({
            previewBasketballCompetitor: !this.state.previewBasketballCompetitor
        }, () => {
            basketballService.previewBasketballCompetitor(
                socket,
                this.state.name1,
                this.state.name2,
                this.state.team1Logo,
                this.state.team2Logo,
                this.state.team1Members,
                this.state.team2Members,
                this.state.previewBasketballCompetitor,
                this.state.currentTeam);
        })
    }

    private showBasketballCompetitor(): void {
        this.setState({
            showBasketballCompetitor: !this.state.showBasketballCompetitor
        }, () => {
            basketballService.showBasketballCompetitor(socket,
                this.state.name1,
                this.state.name2,
                this.state.team1Logo,
                this.state.team2Logo,
                this.state.team1Members,
                this.state.team2Members,
                this.state.showBasketballCompetitor,
                this.state.currentTeam);
        })
    }

    private setBasketballCompetitor(): void {
        basketballService.setBasketballCompetitor(socket,
            this.state.name1,
            this.state.name2,
            this.state.team1Logo,
            this.state.team2Logo,
            this.state.team1Members,
            this.state.team2Members,
            this.state.currentTeam)
    }

    private handTeamLogo1Change(logo: string): void {
        this.setState({
            team1Logo: logo
        });
        this.basketballTeam1logoFormRef.current?.setFieldsValue({
            logoUrl: logo
        });
    }

    private handTeamLogo2Change(logo: string): void {
        this.setState({
            team2Logo: logo
        });
        this.basketballTeam2logoFormRef.current?.setFieldsValue({
            logoUrl: logo
        });
    }

    private handCurrentTeamMembersChange(currentTeamMembers: TeamMembers): void {
        this.state.currentTeam === 0 ?
        this.setState({
            team1Members: currentTeamMembers
        })
        :
        this.setState({
            team2Members: currentTeamMembers
        })
    }

    private previewBasketballLogo(): void {
        this.setState({
            previewBasketballLogo: !this.state.previewBasketballLogo
        }, () => {
            basketballService.previewBasketballLogo(socket, this.state.basketballLogoUrl, this.state.competition, this.state.previewBasketballLogo);
        })
    }

    private showBasketballLogo(): void {
        this.setState({
            showBasketballLogo: !this.state.showBasketballLogo
        }, () => {
            basketballService.showBasketballLogo(socket, this.state.basketballLogoUrl, this.state.competition, this.state.showBasketballLogo);
        })
    }

    private setBasketballLogo(): void {
        basketballService.setBasketballLogo(socket, this.state.basketballLogoUrl, this.state.competition);
    }

    private handLogoCompetitionChange(competition: string): void {
        this.setState({ competition });
        this.basketballTotalScoreFormRef.current?.setFieldsValue({
            competition
        })
    }

    private handTotalCompetitionChange(competition: string): void {
        this.setState({ competition });
        this.basketballLogoUrlFormRef.current?.setFieldsValue({
            competition
        })
    }

    private handBasketballLogoUrlChange(basketballLogoUrl: string): void {
        this.setState({basketballLogoUrl: basketballLogoUrl})
    }

    private showBasketballCaption(): void {
        this.setState({
            showBasketballCaption: !this.state.showBasketballCaption
        }, () => {
            basketballService.showBasketballCaption(socket, this.state.title, this.state.text, this.state.showBasketballCaption);
        })
    }

    private setBasketballCaption(): void {
        basketballService.setBasketballCaption(socket, this.state.title, this.state.text)
    }

    private previewBasketballCaption(): void {
        this.setState({
            previewBasketballCaption: !this.state.previewBasketballCaption
        }, () => {
            basketballService.previewBasketballCaption(socket, this.state.title, this.state.text, this.state.previewBasketballCaption);
        })
    }

    private handTitleChange(title: string): void {
        this.setState({
            title
        })
    }

    private handTextChange(text: string): void {
        this.setState({
            text
        })
    }

    private changeTeam(value: number): void {
        this.setState({
            currentTeam: value
        }, () => {
            this.basketballCompetitorFormRef.current?.setFieldsValue({
                logoUrl: this.state.currentTeam === 0 ? this.state.team1Logo : this.state.team2Logo
            })
        })
    }

    private previewCommentary(): void {
        this.setState({
            previewCommentary: !this.state.previewCommentary
        }, () => {
            basketballService.previewCommentary(socket, this.state.commentator, this.state.guest, this.state.venue, this.state.previewCommentary);
        })
    }

    private showCommentary(): void {
        this.setState({
            showCommentary: !this.state.showCommentary
        }, () => {
            basketballService.showCommentary(socket, this.state.commentator, this.state.guest, this.state.venue, this.state.showCommentary);
        })
    }

    private setCommentary(): void {
        basketballService.setCommentary(socket, this.state.commentator, this.state.guest, this.state.venue);
    }

    private handCommentatorChange(commentator: string): void {
        this.setState({
            commentator
        })
    }

    private handGuestChange(guest: string): void {
        this.setState({
            guest
        })
    }

    private handVenueChange(venue: string): void {
        this.setState({
            venue
        })
    }

    private previewBasketballScoreCard(): void {
        this.setState({
            previewBasketballScoreCard: !this.state.previewBasketballScoreCard
        }, () => {
            basketballService.previewBasketballScoreCard(socket, this.state.team1Logo, this.state.team2Logo, this.state.name1, this.state.name2, this.state.score1, this.state.score2, this.state.word, this.state.previewBasketballScoreCard);
        })
    }

    private showBasketballScoreCard(): void {
        this.setState({
            showBasketballScoreCard: !this.state.showBasketballScoreCard
        }, () => {
            basketballService.showBasketballScoreCard(socket, this.state.team1Logo, this.state.team2Logo, this.state.name1, this.state.name2, this.state.score1, this.state.score2, this.state.word, this.state.showBasketballScoreCard);
        })
    }

    private setBasketballScoreCard(): void {
        basketballService.setBasketballScoreCard(socket, this.state.team1Logo, this.state.team2Logo, this.state.name1, this.state.name2, this.state.score1, this.state.score2, this.state.word);
    }

    private handChangeScore1(score: number): void {
        this.setState({
            score1: score
        })
    }

    private handChangeScore2(score: number): void {
        this.setState({
            score2: score
        })
    }

    private handChangeName1(name: string): void {
        this.setState({
            name1: name
        })
    }

    private handChangeName2(name: string): void {
        this.setState({
            name2: name
        })
    }
}
