import axios from "axios";
import { EUVP_BASE_URL, CAPTION_BASE_URL } from "../common/constants";
import { CaptchaResponse, EuvpLoginResponse } from "../common/types";

const GET_CAPTCHA = `${CAPTION_BASE_URL}/captionserver/v1/euvp/captcha`;
const LOGIN_URL = `${CAPTION_BASE_URL}/captionserver/v1/euvp/login`;
const STORAGE_ITEM_TOKEN = 'token';
class EuvpService {

    public async getCaptcha(): Promise<CaptchaResponse | undefined> {
        return (await axios.get(GET_CAPTCHA)).data?.data as CaptchaResponse;
    }

    public async login(username: string, password: string, captcha: string, captchaId: string): Promise<EuvpLoginResponse> {
        const response = (await axios.post(LOGIN_URL, {
            username,
            pwd: Buffer.from('**'+ password + '**').toString('base64'),
            captcha,
            captcha_id: captchaId,
        })).data as EuvpLoginResponse;
        return response;
    }

    public setToken(token: string): void {
        sessionStorage.setItem(STORAGE_ITEM_TOKEN, token);
    }

}

export const euvpService = new EuvpService();
