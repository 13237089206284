import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Common } from './page/common/common';
import { GolfController } from "./page/golf/golf";
import { Template } from "./page/template/Template";
import { Home } from "./page/home/Home";
import { SelectSwitcher } from "./page/selectSwitcher/SelectSwitcher";
import { Basketball } from "./page/basketball/Basketball";

export class App extends React.Component {
  public render() {
    return (
        <div className="App">
          <Switch>
            <Route key="advancecg" path="/advancecg" exact component={Home} />
            <Route key="selectSwitcher" path="/selectSwitcher" exact component={SelectSwitcher} />
            <Route key="template" path="/template/:id" exact component={(props: any)=><Template {...props}/>} />
            <Route key="golf" path="/golf/:id" exact component={(props: any)=><GolfController {...props}/>} />
            <Route key="caption" path="/caption/:id" exact component={(props: any)=><Common {...props}/>} />
            <Route key="basketball" path="/basketball/:id" exact component={(props: any)=><Basketball {...props}/>} />
          </Switch>
        </div>
    );
  }
}
