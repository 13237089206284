import './Login.scss';
import React from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { LockOutlined, UserOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { LoginRequest } from '../../common/types';
import { euvpService } from '../../services/EuvpService';
import { isDev } from "../../common/utils";

export interface LoginProps {
    onLogin: (username: string, password: string, enableEuvp: boolean, captcha: string, captchaPicId?: string) => void;
}

interface LoginState {
    captchaPicUrl?: string;
    captchaPicId?: string;
    enableEuvp: boolean;
}

export class Login extends React.Component<LoginProps, LoginState> {
    public constructor(props: LoginProps) {
        super(props);
        this.state = {
            enableEuvp: true,
        }
    }
    public async componentDidMount(): Promise<void> {
        await this.getCaptcha();
    }

    public render() {
        return (
            <div className="Login">
                <div className='loginDiv'>
                    <div className='title'>
                        云转播高级图文助手
                    </div>
                    <Form
                        className='loginForm'
                        initialValues={{
                        }}
                        layout="vertical"
                        onFinish={(values: LoginRequest) => this.handleLogin(values)}>
                        <Form.Item name="username">
                            <Input size="middle" prefix={<UserOutlined style={{ color: 'rgba(250,250,250,.80)' }} />} placeholder="请输入用户名" style={{width: '486px', background: '#2603A8', borderRadius: '33px', borderStyle: 'none'}}/>
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true, message: '密码不能为空' }]}>
                            <Input.Password className='inputPassword' size="large" prefix={<LockOutlined style={{ color: 'rgba(250,250,250,.80)' }} />} placeholder="请输入密码" style={{width: '486px'}}/>
                        </Form.Item>
                        {
                            this.state.enableEuvp &&
                            <div className="Captcha">
                                <Form.Item name="captcha" rules={[{required: true, message: "请输入验证码"}]}>
                                    <Input  size="middle" prefix={<CheckCircleOutlined style={{color: 'rgba(250,250,250,.80)'}}/>} placeholder="请输入验证码" style={{width: '486px', background: '#2603A8', borderRadius: '33px', borderStyle: 'none'}}/>
                                </Form.Item>
                                <Form.Item className="captchaPic">
                                    <img alt="" className='captchaImg' src={this.state.captchaPicUrl} />
                                    <div className='captchaRefresh' onClick={() => this.getCaptcha()}>换一张</div>
                                </Form.Item>
                            </div>

                        }
                        {
                            isDev() &&
                            <Checkbox
                                style={{color: '#FFFFFF', marginLeft: '20px'}}
                                checked={this.state.enableEuvp}
                                onChange={(e) => this.handleEnableEuvp(e.target.checked)}
                            >
                                新用户登录
                            </Checkbox>
                        }
                        <Form.Item>
                            <Button size="middle" type="primary" htmlType="submit">登录</Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }

    private async handleLogin(values: LoginRequest): Promise<void> {
        this.props.onLogin(values.username, values.password, this.state.enableEuvp, values.captcha, this.state.captchaPicId);
        await this.getCaptcha();
    }

    public handleEnableEuvp(checked: boolean): void {
        this.setState({
            enableEuvp: checked
        })
    }

    private async getCaptcha(): Promise<void> {
        let captchaData = await euvpService.getCaptcha();
        if (captchaData) {
            this.setState({
                captchaPicUrl: captchaData.img,
                captchaPicId: captchaData.id,
            })
        } else {
            message.error('验证码获取失败');
        }
    }
}
