import React from 'react';
import './BasketballTotalScore.scss';
import { Form, Input, InputNumber } from "antd";
import { FormInstance } from "antd/es/form";

interface BasketballTotalScoreProps {
    formRef: React.RefObject<FormInstance>;
    competition: string;
    word: string;
    score1: number;
    score2: number;
    previewBasketballTotalScore: boolean;
    showBasketballTotalScore: boolean;
    handChangeScore1: (score: number) => void;
    handChangeScore2: (score: number) => void;
    handWordChange: (word: string) => void;
    handCompetitionChange: (competition: string) => void;
    show: () => void;
    preview: () => void;
    set: () => void;
}

export class BasketballTotalScore extends React.Component<BasketballTotalScoreProps, unknown>{

    public render(): JSX.Element {
        return (
            <Form
                ref={this.props.formRef}
                className='basketballTotalScore'
            >
                <div className='basketballTotalScoreDiv'>
                    <p>中场/全场总比分</p>
                </div>
                <div style={{width: '100%', height: '100px'}}>
                    <div style={{marginTop: '18px'}}>
                        <p style={{margin: '3px 25px auto 20px', display: 'flex', float: 'left'}}>比赛名称：</p>
                        <Form.Item
                            name="competition"
                            rules={[
                                // { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                { max: 14, message: '比赛名称不超过14个字' }]}
                        >
                            <Input
                                placeholder={'不超过14个字'}
                                value={this.props.competition}
                                style={{width: '90%', background: '#17242D', color: '#FFFFFF'}}
                                bordered={false}
                                maxLength={14}
                                onChange={(e) => this.props.handCompetitionChange(e.target.value)}
                            />
                        </Form.Item>
                    </div>
                    <div style={{paddingTop: '8px'}}>
                        <p style={{margin: '3px 25px auto 20px', display: 'flex', float: 'left'}}>比分标题：</p>
                        <Form.Item
                            name="word"
                            rules={[
                                // { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                { max: 6, message: '比分标题不超过6个字' }]}
                        >
                            <Input
                                placeholder={'不超过6个字'}
                                value={this.props.word}
                                style={{width: '90%', background: '#17242D', color: '#FFFFFF'}}
                                bordered={false}
                                maxLength={6}
                                onChange={(e) => this.props.handWordChange(e.target.value)}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div style={{display: 'flex', float: 'left', marginTop: '25px'}}>
                    <p style={{margin: '5px 10px auto 24px'}}>主队得分：</p>
                    <InputNumber
                        value={this.props.score1}
                        min={0}
                        max={999}
                        precision={0}
                        bordered={false}
                        onChange={(value) => this.props.handChangeScore1(value === null ? 0 : value)}
                    />
                    <p style={{margin: '5px 10px auto 36px'}}>客队得分：</p>
                    <InputNumber
                        value={this.props.score2}
                        min={0}
                        max={999}
                        precision={0}
                        bordered={false}
                        onChange={(value) => this.props.handChangeScore2(value === null ? 0 : value)}
                    />
                </div>
                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: '123px'}}>
                    <button onClick={() => this.props.preview()}
                            style={{background: `${this.props.previewBasketballTotalScore ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                        {this.props.previewBasketballTotalScore ? '隐藏' : '预览'}
                    </button>
                    <button onClick={() => this.props.show()}
                            style={{background: `${this.props.showBasketballTotalScore ? '#4F5E65' : '#05A905'}`, borderRadius: '6px', marginLeft: '20px'}}>
                        {this.props.showBasketballTotalScore ? '隐藏' : '输出'}
                    </button>
                    <button onClick={() => this.props.set()}
                            style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                        刷新
                    </button>
                </div>
            </Form>
        );
    }

}
