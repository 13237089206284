import React from 'react';
import './Template.scss';
import golf from './img/golf.png';
import common from './img/common.png';
import basketball from './img/basketball.png';
import football from './img/football.png';
import { Link, RouteComponentProps } from "react-router-dom";
import {goBack} from "../../common/utils";

interface TemplateProps extends RouteComponentProps<{id: string}>{
}
export class Template extends React.Component<TemplateProps, unknown>{

    public render(): JSX.Element {
        return (
            <div className='template'>
                <i title={'返回上一级'} className={'back fas fa-arrow-left'} onClick={() => goBack()}/>
                <div className='div1'>
                    <div className='golf'>
                        <div className='golfDiv'>
                            <img src={golf} alt=''/>
                            <div className='golfText'>
                                <p>高尔夫比赛</p>
                                <p>高尔夫赛事图文模板，含开场预告信息牌、天气信息牌、选手个人成绩牌、选手综合成绩牌、赛事总排行榜、角标信息等。</p>
                                <Link to={`/golf/${this.props.match.params.id}`}>
                                    <button>
                                        立即使用
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='football'>
                        <div className='footballDiv'>
                            <img src={football} alt=''/>
                            <div className='footballText'>
                                <p>足球比赛</p>
                                <p>足球赛事图文模板，含开场预告信息牌、正计时、倒计时、选手个人成绩牌、选手综合成绩牌、赛事总排行榜、角标信息等。</p>
                                <button>
                                    敬请期待
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='div2'>
                    <div className='basketball'>
                        <div className='basketballDiv'>
                            <img src={basketball} alt=''/>
                            <div className='basketballText'>
                                <p>篮球比赛</p>
                                <p>篮球赛事图文模板，含开场预告信息牌、倒计时、选手个人成绩牌、选手综合成绩牌、总排行榜、角标信息等。</p>
                                <Link to={`/basketball/${this.props.match.params.id}`}>
                                    <button>
                                        立即使用
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='common'>
                        <div className='commonDiv'>
                            <img src={common} alt=''/>
                            <div className='commonText'>
                                <p>通用模板</p>
                                <p>通用活动图文模板，含开场预告信息牌、比分牌、字幕条、角标信息等。</p>
                                <Link to={`/caption/${this.props.match.params.id}`}>
                                    <button>
                                        立即使用
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
